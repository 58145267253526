import { useState } from 'react';
import {
    DeleteSavedCreditCardState,
    SavedCreditCardState,
} from '../shared/SavedCreditCardAliasState';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import { useStore, useUpdate } from 'dg-web-shared/lib/Flux';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Localized } from '../../common/components/Localized';
import { SlideInForm } from '../root/components/SlideInForm';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { LabeledText } from '../../ui/typo/LabeledText.tsx';
import {
    DeleteConfirmQuestion,
    padMonth,
} from '../top-up-balance/EPaymentWithSavedCCAlias';
import { PaymentMethodEnum } from '../../api/Http';
import { Message } from 'dg-web-shared/lib/Localized';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { HandleHttpStati } from '../../ui/modals/HandleHttpStati';
import { StoreAliasRequest } from '../../common/payment/StoreAliasRequest';
import {
    AdditionalCosts,
    SINGLE_TRANSACTION_COST,
} from '../../common/payment/FormTextSnippets';
import {
    BlockStatus,
    PaymentMethodItemBlock,
} from '../root/components/ItemBlock';
import { ArrowPosition, InlineAlertBox } from '../../ui/modals/Confirmable';
import { numberToPrice } from '../../utils/NumberFormatter';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { Balance } from '../../common/state/AccountBalanceState';
import {
    CreditCardAlias,
    CreditCardExpiration,
    PaymentCardType,
    paymentMethodTranslations,
    SavedCreditCardAliasResponseTag,
    WalletIndicator,
} from 'dg-web-shared/model/PaymentAlias';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import { PaymentMethodStatus } from './PaymentMethodStatus';
import {
    PaymentCategory,
    paymentCategoryFromSelectedPaymentFlow,
    SelectedPaymentFlow,
} from '../../common/payment/Payment';
import { OnlinePaymentSelection } from '../top-up-balance/ActionableItem';
import { navCategoryForPaymentAlias } from '../top-up-balance/EPaymentMode';
import { RedWarnIcon24 } from '../../common/components/RedWarnIcon';
import { useParkingpayLanguage } from '../../utils/UseParkingpayLanguage';
import { logAction } from '../../utils/ActionLog';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function DirectMethod({
    status,
    paymentMethod,
}: {
    status: PaymentMethodStatus;
    paymentMethod: PaymentMethodEnum;
}) {
    const { storeState } = useStore(s => ({
        alias: SavedCreditCardState.get(s),
        accountBalance: new AccountBalanceState.StateSlice(s).state,
    }));

    const alias =
        storeState.alias.status === RequestStatus.SUCCESS &&
        storeState.alias.data.tag === SavedCreditCardAliasResponseTag.FOUND
            ? storeState.alias.data.creditCardAlias
            : null;
    const [singlePaymentSlideInOpen, setSinglePaymentSlideInOpen] =
        useState(false);
    const isActive = status === PaymentMethodStatus.ACTIVE;
    const rightTitle =
        isActive && (alias == null || alias.lastDirectFailed) ? (
            <RedWarnIcon24 />
        ) : alias ? (
            <Localized {...paymentMethodTranslations[alias.paymentMethod]} />
        ) : null;
    return (
        <>
            <PaymentMethodItemBlock
                title={
                    <Localized
                        de="Einzelzahlung"
                        fr="Paiement individuel"
                        it="Pagamento singolo"
                        en="Individual payment"
                    />
                }
                status={isActive ? BlockStatus.ACTIVE : BlockStatus.INACTIVE}
                rightTitle={rightTitle}
                onClick={() => setSinglePaymentSlideInOpen(true)}
            />
            <DirectPayMethodsSlideIn
                paymentMethod={paymentMethod}
                alias={alias}
                aliasReq={storeState.alias}
                accountBalance={storeState.accountBalance.data}
                open={singlePaymentSlideInOpen}
                onClose={() => setSinglePaymentSlideInOpen(false)}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={
                    <Localized
                        de="Zahlungsmittel"
                        fr="Moyen de paiement"
                        it="Mezzo di pagamento"
                        en="Means of payment"
                    />
                }
            />
        </>
    );
}

const DirectPayMethodsSlideIn = portalSlideIn(DirectPayMethods);

export function DirectPayMethods({
    paymentMethod,
    alias,
    aliasReq,
    accountBalance,
}: {
    paymentMethod: PaymentMethodEnum;
    alias: CreditCardAlias | null;
    aliasReq: SavedCreditCardState.State;
    accountBalance: Balance;
}) {
    const [paymentFlow, setPaymentFlow] = useState<SelectedPaymentFlow | null>(
        null,
    );
    const [openSlideIn, setOpenSlideIn] = useState(false);

    return (
        <>
            <OnlinePaymentSelection
                currentAlias={alias}
                onClick={(p: SelectedPaymentFlow) => {
                    setPaymentFlow(p);
                    setOpenSlideIn(true);
                }}
            />
            <SelectedPayment
                open={openSlideIn}
                onClose={() => setOpenSlideIn(false)}
                aliasReq={aliasReq}
                alias={alias}
                paymentFlow={paymentFlow}
                paymentMethod={paymentMethod}
                accountBalance={accountBalance}
            />
            <DirectPayAnnotation />
        </>
    );
}

function SelectedPayment({
    open,
    onClose,
    aliasReq,
    alias,
    paymentMethod,
    paymentFlow,
    accountBalance,
}: {
    open: boolean;
    onClose: () => void;
    aliasReq: SavedCreditCardState.State;
    alias: CreditCardAlias | null;
    paymentMethod: PaymentMethodEnum;
    paymentFlow: SelectedPaymentFlow | null;
    accountBalance: Balance;
}) {
    if (paymentFlow === null) {
        return null;
    }

    const data = deriveData(aliasReq, paymentFlow, paymentMethod);
    const aliasIsFailed = alias?.lastDirectFailed;
    const paymentCategory = paymentCategoryFromSelectedPaymentFlow(paymentFlow);
    const saldoIsPositive = accountBalance.balance > 0;
    const balanceText = accountBalance
        ? numberToPrice(accountBalance.balance)
        : '-';

    return (
        <>
            {data.status === DirectMethodStatus.ACTIVE && (
                <ActiveMethod
                    open={open}
                    onClose={onClose}
                    paymentCategory={paymentCategory}
                    saldoIsPositive={saldoIsPositive}
                    balanceText={balanceText ? balanceText : ''}
                    aliasData={data}
                    lastFailed={aliasIsFailed}
                />
            )}
            {data.status === DirectMethodStatus.INACTIVE && (
                <InactiveMethod
                    open={open}
                    onClose={onClose}
                    paymentCategory={paymentCategory}
                    saldoIsPositive={saldoIsPositive}
                    balanceText={balanceText ? balanceText : ''}
                />
            )}
            {data.status === DirectMethodStatus.INACTIVE_BUT_ALIAS && (
                <InactiveButAliasMethod
                    open={open}
                    onClose={onClose}
                    paymentCategory={paymentCategory}
                    saldoIsPositive={saldoIsPositive}
                    balanceText={balanceText ? balanceText : ''}
                    aliasData={data}
                    lastFailed={alias?.lastDirectFailed}
                />
            )}
        </>
    );
}

function deriveData(
    aliasReq: SavedCreditCardState.State,
    method: SelectedPaymentFlow,
    paymentMethod: PaymentMethodEnum,
): Active | Inactive | InactiveButAlias {
    if (
        aliasReq.data &&
        aliasReq.data.tag === SavedCreditCardAliasResponseTag.FOUND
    ) {
        const alias = aliasReq.data.creditCardAlias;
        if (navCategoryForPaymentAlias(alias) === method) {
            return {
                status:
                    paymentMethod === PaymentMethodEnum.DIRECT
                        ? DirectMethodStatus.ACTIVE
                        : DirectMethodStatus.INACTIVE_BUT_ALIAS,
                maskedCreditCardNumber:
                    alias.paymentMethod !== PaymentCardType.TWI
                        ? alias.maskedCreditCardNumber
                        : null,
                expiration: alias.expiration,
                paymentMethod: alias.paymentMethod,
                walletIndicator: alias.walletIndicator,
            };
        }
    }
    return { status: DirectMethodStatus.INACTIVE };
}

enum DirectMethodStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE_BUT_ALIAS = 'INACTIVE_BUT_ALIAS',
    INACTIVE = 'INACTIVE',
}

interface Active {
    status: DirectMethodStatus.ACTIVE;
    maskedCreditCardNumber: string | null;
    expiration: CreditCardExpiration | null;
    paymentMethod: PaymentCardType;
    walletIndicator: WalletIndicator;
}

interface Inactive {
    status: DirectMethodStatus.INACTIVE;
}

interface InactiveButAlias {
    status: DirectMethodStatus.INACTIVE_BUT_ALIAS;
    maskedCreditCardNumber: string | null;
    expiration: CreditCardExpiration | null;
    paymentMethod: PaymentCardType;
    walletIndicator: WalletIndicator;
}

function ActiveMethod({
    open,
    onClose,
    paymentCategory,
    saldoIsPositive,
    balanceText,
    aliasData,
    lastFailed,
}: {
    open: boolean;
    onClose: () => void;
    paymentCategory: PaymentCategory;
    saldoIsPositive: boolean;
    balanceText: string;
    aliasData: Active;
    lastFailed?: boolean;
}) {
    const language = useParkingpayLanguage();
    const [storeAlias, sendStoreAlias, resetStoreAlias] =
        StoreAliasRequest.use();
    const [deleteAlias, sendDeleteAlias, resetDeleteAlias] =
        DeleteSavedCreditCardState.use(onClose);

    const [displayConfirmationBox, setDisplayConfirmationBox] = useState(false);
    const isExpired = Boolean(aliasData.expiration?.isExpired);

    return (
        <FormSlideIn
            open={open}
            pending={
                storeAlias.status !== RequestStatus.NEVER_EXECUTED ||
                deleteAlias.status !== RequestStatus.NEVER_EXECUTED
            }
            onClose={onClose}
            secondaryButtonCaption={
                paymentCategory !== PaymentCategory.TWINT
                    ? DELETE_CARD_MESSAGE
                    : DELETE_TWINT_MESSAGE
            }
            onSecondaryButtonClick={() => {
                setDisplayConfirmationBox(true);
            }}
            primaryButtonCaption={
                aliasData.walletIndicator !== null
                    ? null
                    : isExpired
                      ? {
                            de: 'Aktualisieren',
                            fr: 'Corriger',
                            it: 'Aggiorna',
                            en: 'Update',
                        }
                      : lastFailed
                        ? {
                              de: 'Überprüfen',
                              fr: 'Vérifier',
                              it: 'Verifica',
                              en: 'Verify',
                          }
                        : {
                              de: 'Ersetzen',
                              fr: 'Remplacer',
                              it: 'Sostituisci',
                              en: 'Replace',
                          }
            }
            onPrimaryButtonClick={() =>
                sendStoreAlias({
                    useAlias: isExpired || Boolean(lastFailed),
                    paymentCategory: paymentCategory,
                    uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT,
                    language: language,
                })
            }
            title={
                <Localized
                    de="Einzelzahlung"
                    fr="Paiement individuel"
                    it="Pagamento singolo"
                    en="Individual payment"
                />
            }
            portal={SlideInPortalId.USER_ACCOUNT}
        >
            <CardInfo aliasData={aliasData} />

            {(lastFailed || isExpired) && (
                <InvalidAliasInfobox
                    isExpired={isExpired}
                    isTWINT={paymentCategory === PaymentCategory.TWINT}
                />
            )}

            <DirectInfo
                saldoIsPositive={saldoIsPositive}
                balanceText={balanceText}
                paymentCategory={paymentCategory}
            />

            <HandleHttpStati
                serverHooks={[
                    [deleteAlias, resetDeleteAlias],
                    [storeAlias, resetStoreAlias],
                ]}
                showSpinner={true}
                ignore400={false}
            />
            {displayConfirmationBox && (
                <DeleteConfirmQuestion
                    onConfirm={() => {
                        sendDeleteAlias();
                        setDisplayConfirmationBox(false);
                    }}
                    onCancel={() => setDisplayConfirmationBox(false)}
                />
            )}
        </FormSlideIn>
    );
}

function InactiveMethod({
    open,
    onClose,
    paymentCategory,
    saldoIsPositive,
    balanceText,
}: {
    open: boolean;
    onClose: () => void;
    paymentCategory: PaymentCategory;
    saldoIsPositive: boolean;
    balanceText: string;
}) {
    const language = useParkingpayLanguage();
    const [storeAlias, sendStoreAlias, resetAlias] = StoreAliasRequest.use();
    return (
        <FormSlideIn
            open={open}
            pending={storeAlias.status === RequestStatus.PENDING}
            onClose={onClose}
            primaryButtonCaption={
                paymentCategory !== PaymentCategory.TWINT
                    ? {
                          de: 'Karte hinzufügen',
                          fr: 'Ajouter carte',
                          it: 'Aggiungi carta',
                          en: 'Add card',
                      }
                    : {
                          de: 'TWINT hinzufügen',
                          fr: 'Ajouter TWINT',
                          it: 'Aggiungi TWINT',
                          en: 'Add TWINT',
                      }
            }
            onPrimaryButtonClick={() =>
                sendStoreAlias({
                    useAlias: false,
                    paymentCategory: paymentCategory,
                    uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT,
                    language: language,
                })
            }
            title={
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Means of payment"
                />
            }
            portal={SlideInPortalId.USER_ACCOUNT}
        >
            <DirectInfo
                saldoIsPositive={saldoIsPositive}
                balanceText={balanceText}
                paymentCategory={paymentCategory}
            />

            <HandleHttpStati
                showSpinner={true}
                serverHooks={[[storeAlias, resetAlias]]}
                ignore400={false}
            />
        </FormSlideIn>
    );
}

const DELETE_CARD_MESSAGE = {
    de: 'Karte löschen',
    fr: 'Effacer carte',
    it: 'Elimina carta',
    en: 'Delete card',
};

const DELETE_TWINT_MESSAGE = {
    de: 'Löschen',
    fr: 'Effacer',
    it: 'Elimina',
    en: 'Delete',
};

function InactiveButAliasMethod({
    open,
    onClose,
    saldoIsPositive,
    balanceText,
    lastFailed,
    paymentCategory,
    aliasData,
}: {
    open: boolean;
    onClose: () => void;
    saldoIsPositive: boolean;
    balanceText: string;
    lastFailed?: boolean;
    paymentCategory: PaymentCategory;
    aliasData: InactiveButAlias;
}) {
    const language = useParkingpayLanguage();
    const update = useUpdate();
    const [storeAlias, sendStoreAlias, resetStoreAlias] =
        StoreAliasRequest.use();
    const [deleteAlias, sendDeleteAlias, resetDeleteAlias] =
        DeleteSavedCreditCardState.use(onClose);
    const [write, setPaymentTypeToDirect, resetPaymentTypeToDirect] =
        usePaymentTypeChange();
    useServerSuccessEffect(write, () => {
        update(s => {
            new PaymentTypeState.StateSlice(s).reset();
            new AccountBalanceState.StateSlice(s).reset();
            logAction(s, 'set-payment-method', 'DIRECT');
            return 'log-and-reset-payment-type-and-account-balance-state';
        });
        onClose;
    });

    const [displayConfirmationBox, setDisplayConfirmationBox] = useState(false);

    return (
        <FormSlideIn
            open={open}
            pending={
                write.status !== RequestStatus.NEVER_EXECUTED ||
                storeAlias.status !== RequestStatus.NEVER_EXECUTED ||
                deleteAlias.status !== RequestStatus.NEVER_EXECUTED
            }
            onClose={onClose}
            secondaryButtonCaption={
                paymentCategory !== PaymentCategory.TWINT
                    ? DELETE_CARD_MESSAGE
                    : DELETE_TWINT_MESSAGE
            }
            onSecondaryButtonClick={() => {
                setDisplayConfirmationBox(true);
            }}
            primaryButtonCaption={{
                de: 'Aktivieren',
                fr: 'Activer',
                it: 'Attiva',
                en: 'Activate',
            }}
            onPrimaryButtonClick={
                Boolean(aliasData.expiration?.isExpired) || Boolean(lastFailed)
                    ? () =>
                          sendStoreAlias({
                              useAlias: true,
                              paymentCategory: paymentCategory,
                              uiPaymentOrigin:
                                  UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT,
                              language: language,
                          })
                    : () =>
                          setPaymentTypeToDirect({
                              activePaymentType: PaymentMethodEnum.DIRECT,
                              ticketId: null,
                          })
            }
            title={
                <Localized
                    de="Einzelzahlung"
                    fr="Paiement individuel"
                    it="Pagamento singolo"
                    en="Individual payment"
                />
            }
            portal={SlideInPortalId.USER_ACCOUNT}
        >
            <CardInfo aliasData={aliasData} />

            <DirectInfo
                saldoIsPositive={saldoIsPositive}
                balanceText={balanceText}
                paymentCategory={paymentCategory}
            />

            <HandleHttpStati
                showSpinner={true}
                serverHooks={[
                    [deleteAlias, resetDeleteAlias],
                    [storeAlias, resetStoreAlias],
                    [write, resetPaymentTypeToDirect],
                ]}
                ignore400={false}
            />
            {displayConfirmationBox && (
                <DeleteConfirmQuestion
                    onConfirm={() => {
                        sendDeleteAlias();
                        setDisplayConfirmationBox(false);
                    }}
                    onCancel={() => setDisplayConfirmationBox(false)}
                />
            )}
        </FormSlideIn>
    );
}

export function usePaymentTypeChange() {
    return useServerWrite<
        { activePaymentType: PaymentMethodEnum; ticketId: string | null },
        null
    >(() => ({
        url: '/ui-api/customer-account/payment-type',
        method: RequestMethod.PUT,
    }));
}

const FormSlideIn = portalSlideIn(function EditForm(p: {
    pending: boolean;
    secondaryButtonCaption?: Message;
    onSecondaryButtonClick?: () => void;
    primaryButtonCaption: Message | null;
    onPrimaryButtonClick: () => void;
    children: React.ReactNode;
}) {
    return (
        <SlideInForm
            secondaryButton={
                p.secondaryButtonCaption && p.onSecondaryButtonClick
                    ? {
                          disabled: p.pending,
                          label: <Localized {...p.secondaryButtonCaption} />,
                          onClick: p.onSecondaryButtonClick,
                      }
                    : undefined
            }
            primaryButton={
                p.primaryButtonCaption && p.onPrimaryButtonClick
                    ? {
                          disabled: p.pending,
                          label: <Localized {...p.primaryButtonCaption} />,
                          onClick: p.onPrimaryButtonClick,
                      }
                    : undefined
            }
        >
            {p.children}
        </SlideInForm>
    );
});

function DirectInfo(p: {
    saldoIsPositive: boolean;
    balanceText: string;
    paymentCategory: PaymentCategory;
}) {
    const paymentMean =
        p.paymentCategory !== PaymentCategory.TWINT
            ? {
                  de: 'auf die Karte',
                  fr: 'sur la carte',
                  it: 'sulla carta',
                  en: 'to the card',
              }
            : {
                  de: 'in der TWINT App',
                  fr: `dans l'app TWINT`,
                  it: `nell'app TWINT`,
                  en: 'in the TWINT App',
              };

    return (
        <div
            className={css({
                ...Typo.robotoRegular,
                fontSize: 16,
                marginBottom: '16px',
                lineHeight: '22px',
                color: Colors.action_f,
            })}
        >
            <p>
                <Localized
                    de={`Die Transaktionen werden sofort und einzeln ${paymentMean.de} belastet.`}
                    fr={`Les transactions sont débitées immédiatement et individuellement ${paymentMean.fr}.`}
                    it={`Le transazioni vengono addebitate immediatamente e singolarmente ${paymentMean.it}.`}
                    en={`Transactions are debited immediately and individually ${paymentMean.en}.`}
                />
            </p>
            {p.saldoIsPositive && (
                <ConsumeSaldoFirstText balanceText={p.balanceText} />
            )}
            <AdditionalCosts />
        </div>
    );
}

function CardInfo({ aliasData }: { aliasData: Active | InactiveButAlias }) {
    return (
        <div className={css({ marginTop: '12px' })}>
            <LabeledText
                context={InputContext.form}
                label={{
                    de: 'Typ',
                    fr: 'Typ',
                    it: 'Tipo',
                    en: 'Type',
                }}
                compactHeight={1000}
            >
                <Localized
                    {...paymentMethodTranslations[aliasData.paymentMethod]}
                />
            </LabeledText>
            {aliasData.maskedCreditCardNumber && (
                <LabeledText
                    context={InputContext.form}
                    label={{
                        de: 'Kartennummer',
                        fr: 'Numèro de la carte',
                        it: 'Numero della carta',
                        en: 'Card number',
                    }}
                    compactHeight={1000}
                >
                    {getCreditCardDisplayNumber(
                        aliasData.maskedCreditCardNumber,
                        aliasData.walletIndicator,
                    )}
                </LabeledText>
            )}
            {aliasData.expiration && (
                <LabeledText
                    context={InputContext.form}
                    label={{
                        de: 'Ablaufdatum',
                        fr: "Date d'échéance",
                        it: 'Data di scadenza',
                        en: 'Expiration data',
                    }}
                >
                    {padMonth(aliasData.expiration.month)} /{' '}
                    {aliasData.expiration.year}
                </LabeledText>
            )}
        </div>
    );
}

function getCreditCardDisplayNumber(
    ccNumber: string,
    walletIndicator: WalletIndicator,
) {
    if (walletIndicator) {
        return ccNumber;
    }
    return ccNumber.toLowerCase().split(' ').join('');
}

function DirectPayAnnotation() {
    return (
        <div
            className={css`
                ${Typo.robotoMedium}
                padding: 24px 30px;
                color: ${Colors.action_w};
            `}
        >
            <div>
                <Localized
                    de="Zuschlag"
                    fr="Supplément"
                    it="Supplemento"
                    en="Surcharge"
                />
            </div>
            <p
                className={css`
                    ${Typo.robotoRegular}
                    font-size: 14px;
                `}
            >
                <Localized
                    de={`Bei Belastungen, deren Betrag kleiner als CHF 20 ist, wird ein Zuschlag von ${SINGLE_TRANSACTION_COST} Rp. erhoben.`}
                    fr={`Un supplément de ${SINGLE_TRANSACTION_COST} ct. est prélevé pour les débits inférieurs à 20 CHF.`}
                    it={`Per gli addebiti inferiori a CHF 20.- viene riscosso un supplemento di ${SINGLE_TRANSACTION_COST} centesimi.`}
                    en={`A surcharge of ${SINGLE_TRANSACTION_COST} centimes is applied for debits of less than CHF 20.`}
                />
            </p>
        </div>
    );
}

export function AdminFeeAnnotation(p: {
    title: React.ReactNode;
    text: React.ReactNode;
}) {
    return (
        <div
            className={css`
                ${Typo.robotoMedium}
                padding: 24px 30px;
                color: ${Colors.action_w};
            `}
        >
            <div>* {p.title}</div>
            <p
                className={css`
                    ${Typo.robotoRegular}
                    font-size: 14px;
                `}
            >
                {p.text}
            </p>
        </div>
    );
}

export const expiredLabel = (
    <span
        className={css`
            color: ${Colors.red};
        `}
    >
        <Localized
            {...{
                de: 'ABGELAUFEN',
                fr: 'EXPIRÉE',
                it: 'SCADUTA',
                en: 'EXPIRED',
            }}
        />
    </span>
);

export const failedLabel = (
    <span
        className={css`
            color: ${Colors.red};
        `}
    >
        <Localized
            {...{
                de: 'ZAHLUNG ABGELEHNT',
                fr: 'PAIEMENT REFUSÉ',
                it: 'PAGAMENTO RIFIUTATO',
                en: 'PAYMENT REJECTED',
            }}
        />
    </span>
);

export function InvalidAliasInfobox(p: {
    isExpired: boolean;
    isTWINT: boolean;
}) {
    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    {...(p.isExpired
                        ? !p.isTWINT
                            ? {
                                  de: 'Karte abgelaufen',
                                  fr: 'Carte expirée',
                                  it: 'Carta scaduta',
                                  en: 'Card expired',
                              }
                            : {
                                  de: 'TWINT App abgelaufen',
                                  fr: 'App TWINT expirée',
                                  it: 'App TWINT scaduta',
                                  en: 'TWINT app expired',
                              }
                        : {
                              de: 'Zahlung abgelehnt',
                              fr: 'Paiement refusée',
                              it: 'Pagamento rifiutato',
                              en: 'Payment rejected',
                          })}
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            {!p.isExpired ? (
                <p>
                    <Localized
                        {...{
                            de: `Die letzte Zahlung ${
                                !p.isTWINT
                                    ? 'auf diese Karte'
                                    : 'in der TWINT App'
                            } wurde abgelehnt.`,
                            fr: `Le dernier paiement ${
                                !p.isTWINT
                                    ? 'sur cette carte'
                                    : `dans l'app TWINT`
                            } a été rejeté.`,
                            it: `L'ultimo pagamento ${
                                !p.isTWINT
                                    ? 'su questa carta'
                                    : `nell'app TWINT`
                            } è stato rifiutato.`,
                            en: `The last payment ${
                                !p.isTWINT ? 'on this card' : 'in the TWINT app'
                            } has been rejected.`,
                        }}
                    />
                </p>
            ) : null}
            <p>
                <Localized
                    {...{
                        de: `Bis zur Registrierung eines gültigen Zahlungsmittels zur Belastung des ausstehenden Betrags, sind keine weiteren kostenpflichtigen Transaktionen möglich.`,
                        fr: 'Aucune autre transaction payante ne sera possible tant que un moyen de paiement valable ne sera enregistré pour débiter le montant en souffrance.',
                        it: `Non sarà possibile effettuare ulteriori transazioni a pagamento fintanto che non verrà registrato un mezzo di pagamento valido per addebitare l'importo scoperto.`,
                        en: 'No further paid transactions will be possible until a valid means of payment will be registered to debit the overdue amount.',
                    }}
                />
            </p>
        </InlineAlertBox>
    );
}

export function ConsumeSaldoFirstText(p: { balanceText: string }) {
    return (
        <p>
            <Localized
                de={`Der Saldo des Kontos (${p.balanceText}) wird zuerst verbraucht.`}
                fr={`Le solde du compte (${p.balanceText}) sera utilisé en premier.`}
                it={`Il saldo del conto (${p.balanceText}) verrà prima consumato.`}
                en={`The account balance (${p.balanceText}) will be consumed first.`}
            />
        </p>
    );
}
