import { envIsProduction } from 'dg-web-shared/lib/Environment';
import { logAction } from '../../utils/ActionLog';
import { TicketScanDevSlideIn } from '../ticket/TicketScanDevSlideIn';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { useState } from 'react';
import { FooterScanButtonContainer } from '../../layout/components/footer/FooterMobile';
import { VariantButton } from './ParkVariantSelection';
import { css } from '@emotion/css';
import { Localized } from '../../common/components/Localized';
import { getBridge } from '../../Native';
import { ModalErrorBox } from '../../ui/modals/Confirmable';
import { isAndroid, isIos } from 'dg-web-shared/common/utils/BrowserOrigin';
import { useNavigate } from 'react-router-dom';
import { TicketPlusTitle } from '../../common/components/ticketplus/TicketPlusGenericTexts';
import { QRCode } from 'dg-web-shared/ui/icons/QRCode.tsx';

export function QrCodeScanner({ isMobile }: { isMobile: boolean }) {
    const navigate = useNavigate();
    const [ticketScanError, setTicketScanError] =
        useState<ScanErrorType | null>(null);
    const [openDevWebScanner, setOpenDevWebScanner] = useState(false);
    return (
        <>
            <QrCodeScannerFooter
                isMobile={isMobile}
                onNoBridgeAvailable={() => {
                    if (envIsProduction()) {
                        logAction(
                            null,
                            'ticket-scan-from-mobile-web-attempted',
                        );
                        setTicketScanError('mobileWeb');
                    } else {
                        setOpenDevWebScanner(true);
                    }
                }}
                onOutdatedAppVersion={() => {
                    logAction(
                        null,
                        'ticket-scan-from-outdated-app-version-attempted',
                    );
                    setTicketScanError('outdatedAppVersion');
                }}
            />
            <TicketScanDevSlideIn
                portal={SlideInPortalId.PARK_CREATE}
                open={openDevWebScanner}
                onTicketScan={scan => navigate('/ticket/' + scan)}
                onClose={() => setOpenDevWebScanner(false)}
                title={<TicketPlusTitle />}
                headerColor={'darkBlue'}
            />
            {ticketScanError && (
                <TicketScanErrorModal
                    type={ticketScanError}
                    close={() => setTicketScanError(null)}
                />
            )}
        </>
    );
}

function QrCodeScannerFooter({
    isMobile,
    onNoBridgeAvailable,
    onOutdatedAppVersion,
}: {
    isMobile: boolean;
    onNoBridgeAvailable: () => void;
    onOutdatedAppVersion: () => void;
}) {
    if (!isMobile) {
        return null;
    }
    return (
        <FooterScanButtonContainer>
            <VariantButton
                disabled={false}
                icon={
                    <div
                        className={css({
                            height: '48px',
                            width: '40px',
                            marginLeft: '36px',
                        })}
                    >
                        <QRCode />
                    </div>
                }
                text={
                    <div
                        className={css({
                            paddingLeft: '10px',
                            paddingRight: '16px',
                        })}
                    >
                        <Localized
                            de="QR-Code"
                            fr="Code QR"
                            it="Codice QR"
                            en="QR code"
                        />
                    </div>
                }
                onClick={() => {
                    const bridge = getBridge();
                    if (bridge) {
                        if (bridge.scanQRCode) {
                            bridge.scanQRCode();
                        } else {
                            onOutdatedAppVersion();
                        }
                    } else {
                        onNoBridgeAvailable();
                    }
                }}
            />
        </FooterScanButtonContainer>
    );
}

function TicketScanErrorModal({
    type,
    close,
}: {
    type: ScanErrorType;
    close: () => void;
}) {
    return (
        <ModalErrorBox
            titleCaption={
                <Localized
                    de="Scannen nicht möglich"
                    fr="Scannage impossible"
                    it="Scansione non possibile"
                    en="Scan not possible"
                />
            }
            confirmCallback={close}
        >
            <p>
                {type === 'mobileWeb' ? (
                    <Localized
                        de="Aufgrund technischer Einschränkungen ist diese Funktion in der Web-Version von Parkingpay nicht verfügbar."
                        fr="En raison de restrictions techniques, cette fonction n'est pas disponible dans la version web de Parkingpay."
                        it="A causa di restrizioni tecniche, questa funzione non è disponibile nella versione web di Parkingpay."
                        en="Due to technical restrictions, this function is not available in the web version of Parkingpay."
                    />
                ) : (
                    type === 'outdatedAppVersion' && (
                        <Localized
                            de="Aufgrund technischer Einschränkungen ist diese Funktion mit dieser Version von Parkingpay nicht verfügbar."
                            fr="En raison de restrictions techniques, cette fonction n'est pas disponible avec cette version de de Parkingpay."
                            it="A causa di restrizioni tecniche, questa funzione non è disponibile con questa versione di Parkingpay."
                            en="Due to technical restrictions, this function is not available with this version of Parkingpay."
                        />
                    )
                )}
            </p>

            <p>
                {type === 'mobileWeb' ? (
                    <Localized
                        de="Bitte laden Sie die App aus dem "
                        fr="Veuillez télécharger l'app depuis "
                        it="La preghiamo di scaricare l’app da "
                        en="Please download the app from "
                    />
                ) : (
                    type === 'outdatedAppVersion' && (
                        <Localized
                            de="Bitte laden Sie die neueste Version aus dem "
                            fr="Veuillez télécharger la dernière version à partir du "
                            it="La preghiamo di scaricare l'ultima versione dal "
                            en="Please download the most recent version from "
                        />
                    )
                )}
                {isAndroid() ? (
                    <a
                        onClick={() =>
                            logAction(
                                null,
                                'ticket-mobile-web-redirect-to-google-playstore',
                            )
                        }
                        href={
                            'https://play.google.com/store/apps/details?id=ch.parkingcard.customer'
                        }
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        Google Play Store
                    </a>
                ) : (
                    isIos() && (
                        <a
                            onClick={() =>
                                logAction(
                                    null,
                                    'ticket-mobile-web-redirect-to-appstore',
                                )
                            }
                            href={
                                'https://apps.apple.com/ch/app/parkingpay/id1104202443'
                            }
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            Apple Store
                        </a>
                    )
                )}
                <Localized de=" herunter." fr="." it="." en="." />
            </p>
        </ModalErrorBox>
    );
}

type ScanErrorType = 'mobileWeb' | 'outdatedAppVersion';
