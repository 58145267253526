import * as LabeledText from '../../../ui/typo/LabeledText.tsx';
import * as LabeledToggle from 'dg-web-shared/tb-ui/toggle/LabeledToggle';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import React from 'react';

export interface SelectableOption {
    description: string;
    id: number;
    selected: boolean;
}

interface Props {
    onSelect: (id: number) => void;
    selectableOptions: SelectableOption[];
    Label: (n: number) => string;
    multiSelection: boolean;
}

export class SelectableOptions extends React.Component<Props> {
    render() {
        const p = this.props;
        if (p.selectableOptions.length === 0) {
            return null;
        }
        return LabeledText.Factory(
            {
                context: InputContext.inverted,
                label: p.Label(p.selectableOptions.length),
                key: 'zones',
            },
            p.selectableOptions.map((z: SelectableOption) => {
                return LabeledToggle.Factory({
                    context: InputContext.inverted,
                    type: this.props.multiSelection
                        ? LabeledToggle.ToggleType.checkbox
                        : LabeledToggle.ToggleType.radio,
                    key: z.id,
                    label: z.description,
                    selected: z.selected,
                    onClick: (): void => p.onSelect(z.id),
                });
            }),
        );
    }
}
