import { css, keyframes } from '@emotion/css';
import { DateTime } from 'luxon';

import { Slope } from 'dg-web-shared/common/tariff-logic/Tariff';
import { RenderableLicensePlate } from 'dg-web-shared/dto/LicensePlate';
import * as Flux from 'dg-web-shared/lib/Flux';
import { localState, useUpdate } from 'dg-web-shared/lib/Flux';
import { Message } from 'dg-web-shared/lib/Localized';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';
import {
    leftPadZeroIfNotTwoDigits,
    numberToPrice,
} from 'dg-web-shared/lib/NumberFormatter';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { CompactSingleSelection } from 'dg-web-shared/tb-ui/inputs/SingleSelection.tsx';
import { LabeledText } from '../../ui/typo/LabeledText.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../account/root/components/PortalSlidein';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { Localized } from '../../common/components/Localized';
import {
    ParkDurationWheel,
    WheelStyle,
} from '../../common/components/ParkDurationWheel';
import { durationTexts, texts } from '../../common/i18n/GeneralTexts';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import * as LicensePlateState from '../../common/state/LicensePlateState';
import * as SettingsState from '../../common/state/SettingsState';
import * as WriteStateSlice from '../../common/state/WriteStateSlice';
import * as LayoutState from '../../layout/state/LayoutState';
import { ThreeColumnsProperties } from '../../layout/utils/ThreeColumnsProperties';
import * as ParkCreateActions from '../../park-create/actions/ParkCreateActions';
import { LicensePlateEntityComp } from '../../park-create/components/license-plate/Entities';
import {
    StartedCheckinByPlateOnThisDeviceDetailState,
    StartedCheckinByPlateOnThisDeviceState,
} from '../../park-create/components/ParkCreate';
import { ParkTransaction } from '../../park-create/state/zone-transaction/TransactionState';
import * as ZoneTransactionPurchaseState from '../../park-create/state/zone-transaction/ZoneTransactionPurchaseState';
import { Colors } from 'dg-web-shared/ui/vars';
import { fromOldNotation } from '../../Translate';
import { logAction } from '../../utils/ActionLog';
import {
    common,
    runningCheckinByPlateTexts,
} from '../i18n/TransactionsListTexts';
import * as TransactionsListState from '../state/TransactionsListState';
import {
    CheckinDataState,
    CheckinPostFailedPaymentAuthFailedBody,
} from '../../park-create/components/zone-transaction/ZoneEnforcedCheckin';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import * as superagent from 'superagent';
import {
    GenericErrorBody,
    HandleHttpStati,
} from '../../ui/modals/HandleHttpStati';
import { ModalErrorBox } from '../../ui/modals/Confirmable';
import { useTransactionListRefetch } from '../TransactionsListContext';
import { SpinnerModal } from '../../ui/spinner/Spinner.tsx';
import React from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { getTypeString } from 'dg-web-shared/dto/LicensePlateType.ts';
import SurchargeData = CheckinDataState.SurchargeData;

export interface ActiveStartStopTransactionTexts {
    licensePlate: Message;
    vehicle: Message;
    stopTransaction: Message;
    stopping: Message;
    timeEndsAtDate: Message;
    timeEndsAtTime: Message;
    changeParkDuration: Message;
    saveParkDuration: Message;
    price: Message;
    runningTransaction: Message;
    zoneDetails: Message;
    startWithOtherPlate: Message;
}

export namespace StopRequest {
    export function use() {
        const update = useUpdate();
        const refetchTransactionList = useTransactionListRefetch();
        return useServerWrite<number, { id: number }>({
            req: (id: number) => {
                return superagent.put(
                    `/ui-api/customer-account/zone-transactions-v2/${id}`,
                );
            },
            onResponse: (res, _payload) => {
                if (res.status === RequestStatus.SUCCESS) {
                    refetchTransactionList();
                    update(store => {
                        StartedCheckinByPlateOnThisDeviceState.clear(store);
                        new ZoneTransactionPurchaseState.ResponseStateSlice(
                            store,
                        ).reset();

                        TransactionsListState.CheckinByPlateDetailState.refetchSameContext(
                            store,
                            true,
                        );

                        StopRequest.reset(store);

                        new AccountBalanceState.StateSlice(store).reset();
                        ParkTransaction.refetchSameContext(store, true);
                        store.update(ParkCreateActions.parkVariantSelectNone);
                        if (
                            new ThreeColumnsProperties({
                                layout: new LayoutState.StateSlice(store).state,
                            }).variant.oneColumn
                        ) {
                            TransactionsListState.Detail.stateWrite(store, {
                                parkTransactionId: res.data.id,
                            });
                        }
                        return 'on-transaction-stop-success';
                    });
                }
            },
        });
    }

    export type State = WriteStateSlice.State;
    export const { get, setResponse, reset } = WriteStateSlice.generate(
        'transactions-list/TransactionsListState.ParkTransactionStop',
    );
}

export namespace AutoTerminationTimeRequest {
    export interface DetailUpdate {
        id: number;
        autoTerminationTime: DateTime;
    }

    export function use() {
        const update = useUpdate();
        return useServerWrite<DetailUpdate, object, { message: string } | null>(
            {
                req: detailUpdate => {
                    return superagent
                        .put(
                            `/ui-api/customer-account/transactions-list/parktransaction-detail/${detailUpdate.id}`,
                        )
                        .send({
                            autoTerminationTimeCustomer:
                                detailUpdate.autoTerminationTime
                                    .toUTC()
                                    .toISO(),
                        });
                },
                onResponse: res => {
                    if (res.status === RequestStatus.SUCCESS) {
                        update(store => {
                            StartedCheckinByPlateOnThisDeviceDetailState.refetchSameContext(
                                store,
                                false,
                            );
                            TransactionsListState.CheckinByPlateDetailState.refetchSameContext(
                                store,
                                true,
                            );
                            return 'on-running-checkin-detail-update-success';
                        });
                    }
                },
            },
        );
    }
}

interface Props {
    transaction: TransactionsListState.CheckinByPlateDetailState.RunningCheckinByPlate;
    settings: SettingsState.State;
    parkTransactionStop: StopRequest.State;
    portal: SlideInPortalId;
}

export const RunningCheckinByPlate = (props: RunningCheckinByPlateProps) => {
    return (
        <div className={css({ background: Colors.white, height: '100%' })}>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 24px 10px 24px',
                    height: '100%',
                    [Responsive.level2Media]: {
                        padding: '8px 24px 10px 24px',
                    },
                })}
            >
                <div
                    className={css({
                        flexGrow: 1,
                        flexShrink: 0,
                        display: 'flex',
                        flexDirection: 'column',
                    })}
                >
                    <RunningCheckinByPlateContent {...props} />
                </div>

                <div
                    className={css({
                        flexGrow: 0,
                        flexShrink: 1,
                        height: '40px',
                    })}
                />
            </div>
        </div>
    );
};

function RunningCheckinByPlateContent(props: RunningCheckinByPlateProps) {
    const endsAt =
        props.transaction.autoTerminationTime || props.transaction.tariffMaxEnd;

    const [
        autoTerminationTime,
        setAutoTerminationTime,
        resetAutoTerminationTime,
    ] = AutoTerminationTimeRequest.use();

    const [stop, doStop, resetStop] = StopRequest.use();
    const { storeState, update } = Flux.useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        parkTransactionStop: StopRequest.get(store),
        licensePlates: new LicensePlateState.StateSlice(store).state,
    }));
    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                flexGrow: 1,
            })}
        >
            <div
                className={css({
                    flex: '1',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                })}
            >
                <HandleHttpStati
                    serverHooks={[[stop, resetStop]]}
                    showSpinner={true}
                    ignore400={false}
                />
                <HandleHttpStati
                    serverHooks={[
                        [autoTerminationTime, resetAutoTerminationTime],
                    ]}
                    showSpinner={true}
                    ignore400={true}
                />
                {autoTerminationTime.status === RequestStatus.ERROR &&
                    autoTerminationTime.httpStatusCode === 400 && (
                        <ModalErrorBox
                            confirmCallback={resetAutoTerminationTime}
                        >
                            {autoTerminationTime.data?.message ===
                            'PAYMENT_ATTEMPT_AUTHORIZATION_FAILED' ? (
                                <CheckinPostFailedPaymentAuthFailedBody />
                            ) : (
                                <GenericErrorBody />
                            )}
                        </ModalErrorBox>
                    )}
                <div>
                    {props.isPrimaryTransactionInParkCreate && (
                        <h2
                            className={css({
                                margin: 0,
                                ...Typo.robotoBold,
                                fontSize: '16px',
                                color: Colors.darkblue,
                                marginBottom: '12px',
                                [Responsive.level4Media]: {
                                    display: 'none',
                                },
                            })}
                        >
                            <Localized
                                {...runningCheckinByPlateTexts.runningTransaction}
                            />
                        </h2>
                    )}
                    <RemainingTime endsAt={endsAt} />
                    <div
                        className={css({
                            display: 'flex',
                            justifyContent: 'space-between',
                            [Responsive.level2Media]: {
                                marginBottom: '-12px',
                            },
                        })}
                    >
                        <EndsAt endsAt={endsAt} />
                        <LabeledText
                            context={InputContext.activeblue}
                            label={runningCheckinByPlateTexts.price}
                            rightAlign
                        >
                            <div className={css({ minWidth: '100px' })}>
                                {numberToPrice(
                                    props.transaction.autoTerminationPrice,
                                    false,
                                )}
                            </div>
                        </LabeledText>
                    </div>
                </div>
                <div>
                    <LicensePlateDetail
                        showStartWithOtherPlate={
                            props.isPrimaryTransactionInParkCreate &&
                            storeState.licensePlates.data.length > 1
                        }
                        portal={props.portal}
                        update={update}
                        language={storeState.settings.language}
                        licensePlate={{
                            licensePlateNr:
                                props.transaction.licensePlate.licensePlateNr,
                            countryId: props.transaction.licensePlate.country,
                            type: props.transaction.licensePlate.type,
                            remarks: props.transaction.licensePlate.remarks,
                        }}
                    />
                    <ZoneInfoDetail
                        zipCode={props.transaction.zipCode}
                        city={props.transaction.city}
                        operatorName={props.transaction.operatorName}
                        extZoneCode={props.transaction.extZoneCode}
                        portal={props.portal}
                        start={DateTime.fromISO(props.transaction.begin)}
                        name={props.transaction.zoneName}
                        update={update}
                    />
                </div>
            </div>

            <div>
                <ChangeParkDuration
                    {...props}
                    updateAutoTerminationTime={setAutoTerminationTime}
                    settings={storeState.settings}
                    parkTransactionStop={storeState.parkTransactionStop}
                />

                <FullWidthBottomButton
                    label={
                        storeState.parkTransactionStop.pending
                            ? runningCheckinByPlateTexts.stopping
                            : runningCheckinByPlateTexts.stopTransaction
                    }
                    color={FullWidthBottomButtonColor.RED}
                    compactThresholdLength={Responsive.level1}
                    disabled={storeState.parkTransactionStop.pending}
                    onClick={() => {
                        update((s, _id) => {
                            return logAction(s, 'transaction-stop', {
                                id: props.transaction.id,
                            });
                        }, props.transaction.id);
                        doStop(props.transaction.id);
                    }}
                />

                {storeState.parkTransactionStop.pending && (
                    <SpinnerModal
                        visible
                        portal={SlideInPortalId.PARK_CREATE}
                    />
                )}
            </div>
        </div>
    );
}

interface ChangeDurationState {
    slideInOpen: boolean;
    newExactMinutesWithFraction: number | null;
}

const ChangeParkDuration = localState<
    Props & {
        updateAutoTerminationTime: (
            p: AutoTerminationTimeRequest.DetailUpdate,
        ) => void;
    },
    ChangeDurationState
>({ slideInOpen: false, newExactMinutesWithFraction: null }, p => {
    const update = useUpdate();
    const start = DateTime.fromISO(p.transaction.begin);
    const savedMinutes =
        p.state.newExactMinutesWithFraction ||
        DateTime.fromISO(p.transaction.autoTerminationTime).diff(start, [
            'minutes',
        ]).minutes;

    const minMinutes = Math.ceil(
        Math.abs(start.diffNow(['minutes']).minutes) + 5,
    );

    return p.transaction.tariffMaxEnd &&
        p.transaction.tariffCurve &&
        TransactionsListState.CheckinByPlateDetailState.getReferenceTime(
            p.transaction,
        )
            .add(15, 'm')
            .isBefore(p.transaction.tariffMaxEnd) ? (
        <div>
            <FullWidthBottomButton
                label={runningCheckinByPlateTexts.changeParkDuration}
                color={FullWidthBottomButtonColor.BLUE}
                onClick={() =>
                    update(s => {
                        p.setState({ slideInOpen: true });
                        return logAction(
                            s,
                            'running-checkin-by-plate-open-change-duration',
                        );
                    })
                }
                compactThresholdLength={Responsive.level1}
            />
            <DurationSelectionSlideIn
                title={
                    <Localized
                        {...runningCheckinByPlateTexts.changeParkDuration}
                    />
                }
                setMinutes={(mins: number) =>
                    p.setState({
                        newExactMinutesWithFraction: mins,
                    })
                }
                setMin={() => {
                    p.setState({
                        newExactMinutesWithFraction: minMinutes,
                    });
                    update(s =>
                        logAction(s, 'running-checkin-by-plate-toggle-min'),
                    );
                }}
                setMax={() => {
                    p.setState({
                        newExactMinutesWithFraction:
                            p.transaction.tariffMaxMinutes,
                    });
                    update(s =>
                        logAction(
                            s,
                            'running-checkin-by-plate-toggle-max',
                            p.transaction.tariffMaxMinutes,
                        ),
                    );
                }}
                exactMinutesWithFraction={
                    p.state.newExactMinutesWithFraction || savedMinutes
                }
                hasChange={!!p.state.newExactMinutesWithFraction}
                maxMinutes={p.transaction.tariffMaxMinutes}
                minMinutes={minMinutes}
                start={start}
                tariffCurve={p.transaction.tariffCurve}
                minChfStep={p.transaction.minChfStep}
                portal={p.portal}
                open={p.state.slideInOpen}
                surchargeData={p.transaction.surchargeData || []}
                onConfirm={() => {
                    const minutes = Math.ceil(
                        p.state.newExactMinutesWithFraction || savedMinutes,
                    );
                    const autoTerminationTime = start.plus({
                        minutes: minutes,
                    });
                    p.updateAutoTerminationTime({
                        autoTerminationTime: autoTerminationTime,
                        id: p.transaction.id,
                    });
                    p.setState({
                        slideInOpen: false,
                        newExactMinutesWithFraction: null,
                    });
                    update(s =>
                        logAction(
                            s,
                            'running-checkin-by-plate-changed-duration',
                            {
                                minutes,
                                autoTerminationTime,
                            },
                        ),
                    );
                }}
                onClose={() => {
                    p.setState({
                        slideInOpen: false,
                        newExactMinutesWithFraction: null,
                    });
                    update(s =>
                        logAction(
                            s,
                            'running-checkin-by-plate-close-change-duration-without-change',
                        ),
                    );
                }}
            />
        </div>
    ) : null;
});

export const DurationSelectionSlideIn = portalSlideIn<{
    setMinutes: (mins: number) => void;
    setMin: () => void;
    setMax: () => void;
    exactMinutesWithFraction: number | null;
    hasChange: boolean;
    maxMinutes: number;
    minMinutes: number;
    tariffCurve: Slope[];
    minChfStep: number | null;
    start: DateTime;
    onConfirm: () => void;
    surchargeData: SurchargeData[];
}>(p => {
    const nowTruncated = DateTime.local().set({
        second: 0,
        millisecond: 0,
    });
    return (
        <div
            className={css({
                padding: '10px 24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '100%',
            })}
        >
            <ParkDurationWheel
                exactMinutesWithFraction={p.exactMinutesWithFraction}
                maxMinutes={p.maxMinutes}
                minMinutes={p.minMinutes}
                setMinutes={p.setMinutes}
                setMin={p.setMin}
                setMax={p.setMax}
                currentTimeRef={nowTruncated}
                minChfStep={p.minChfStep || 0.05}
                tariffCurve={p.tariffCurve}
                tariffName={null}
                start={p.start}
                compactHeight={Responsive.level2}
                style={WheelStyle.parkingpay}
                surchargeData={p.surchargeData}
                isMaxDurationReducedByTwint={false}
            />
            <FullWidthBottomButton
                label={runningCheckinByPlateTexts.saveParkDuration}
                onClick={p.onConfirm}
                disabled={p.exactMinutesWithFraction == null || !p.hasChange}
                color={FullWidthBottomButtonColor.BLUE}
                compactThresholdLength={Responsive.level3}
            />
        </div>
    );
});

export const EndsAt = (p: { endsAt: string }) => {
    const end = DateTime.fromISO(p.endsAt);
    const now = DateTime.local();
    const isSameDay =
        end.hasSame(now, 'day') &&
        end.hasSame(now, 'month') &&
        end.hasSame(now, 'year');
    return (
        <LabeledText
            context={InputContext.activeblue}
            label={
                isSameDay
                    ? runningCheckinByPlateTexts.timeEndsAtTime
                    : runningCheckinByPlateTexts.timeEndsAtDate
            }
        >
            {isSameDay
                ? end.toLocaleString(DateTime.TIME_24_SIMPLE)
                : end.toFormat(LuxonDateStringFormats.DATE_HOUR_MINUTE)}
        </LabeledText>
    );
};

interface DurationSquareProps {
    duration: Message;
    value: number;
    hideDots: boolean;
}

const blink = keyframes`
    50% {
        opacity: 0;
    }
`;

const DurationSquare = (p: DurationSquareProps) => {
    const marginRight = 18;
    const marginRightNoDot = 0;
    return (
        <div
            className={css({
                minWidth: '70px',
                flexGrow: 1,
                height: '78px',
                background: Colors.darkblue,
                marginRight: !p.hideDots ? marginRight : marginRightNoDot,
                color: Colors.white,
                fontFamily: 'roboto',
                padding: '5px',
                borderRadius: '2px',
                [Responsive.level2Media]: {
                    padding: '0 5px',
                    height: '64px',
                },
                position: 'relative',
            })}
        >
            {!p.hideDots && (
                <div
                    className={css({
                        position: 'absolute',
                        right: '-15px',
                        top: '12px',
                        [Responsive.level2Media]: {
                            top: '5px',
                        },

                        animation: `${blink} 1.5s linear infinite`,
                        color: Colors.darkblue,
                        fontFamily: 'roboto',
                        fontWeight: 500,
                        fontSize: '48px',
                        lineHeight: '48px',
                    })}
                >
                    :
                </div>
            )}
            <div
                className={css({
                    fontSize: '40px',
                    [Responsive.level2Media]: {
                        fontSize: '36px',
                    },
                    fontWeight: 500,
                    textAlign: 'center',
                })}
            >
                {leftPadZeroIfNotTwoDigits(Math.max(p.value, 0))}
            </div>
            <div
                className={css({
                    borderTop: `1px solid ${Colors.white}`,
                    fontWeight: 400,
                    fontSize: '13px',
                    paddingTop: '3px',
                    margin: '0 15%',
                    letterSpacing: '0.3px',
                    textAlign: 'center',
                })}
            >
                <Localized {...p.duration} />
            </div>
        </div>
    );
};

export class RemainingTime extends React.Component<
    { endsAt: string | null },
    object
> {
    private interval: NodeJS.Timeout | undefined;

    componentDidMount() {
        this.interval = setInterval(
            () => this.setState({ dummy: Date.now() }),
            12,
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.props.endsAt) {
            const to = this.props.endsAt;

            const durationLeft = DateTime.fromISO(to).diffNow([
                'days',
                'hours',
                'minutes',
                'seconds',
                'milliseconds',
            ]);

            const segments: { unit: Message; value: number }[] = [];

            /*
               months need special treatment, diffing months and days and hours yiels wierd results
             */
            if (durationLeft.days > 28) {
                const monthSegments = DateTime.fromISO(to).diffNow([
                    'months',
                    'days',
                    'hours',
                ]);
                segments.push({
                    unit: durationTexts.monthsShort,
                    value: monthSegments.months,
                });
                segments.push({
                    unit: durationTexts.daysShort,
                    value: monthSegments.days,
                });
                segments.push({
                    unit: durationTexts.hoursShort,
                    value: Math.floor(monthSegments.hours),
                });
            } else {
                if (durationLeft.days > 0 || segments.length > 0) {
                    segments.push({
                        unit: durationTexts.daysShort,
                        value: durationLeft.days,
                    });
                }

                segments.push({
                    unit: durationTexts.hoursShort,
                    value: durationLeft.hours,
                });

                segments.push({
                    unit: durationTexts.minutesShort,
                    value: durationLeft.minutes,
                });
                segments.push({
                    unit: durationTexts.secondsShort,
                    value: durationLeft.seconds,
                });
            }

            return (
                <div
                    className={css({
                        marginBottom: '12px',
                    })}
                >
                    <div
                        className={css({
                            display: 'flex',
                            justifyContent: 'center',
                        })}
                    >
                        {segments.slice(0, 3).map((component, i) => (
                            <DurationSquare
                                key={i}
                                value={component.value}
                                duration={component.unit}
                                hideDots={i === 2}
                            />
                        ))}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

interface ZoneInfoDetailProps {
    zipCode: string;
    city: string;
    name: string;
    extZoneCode: number;
    operatorName: string;
    portal: SlideInPortalId;
    update: Flux.Updater;
    start: DateTime;
}

export const ZoneInfoDetail = localState<
    ZoneInfoDetailProps,
    { open: boolean }
>({ open: false }, p => {
    return (
        <div>
            <CompactSingleSelection
                context={InputContext.activeblue}
                onClick={() => {
                    p.setState({ open: true });
                    p.update(s =>
                        logAction(s, 'running-checkin-by-plate-open-zone-info'),
                    );
                }}
                veryCompactThresholdHeight={Responsive.level3}
            >
                {`${p.extZoneCode} ${p.name}`}
            </CompactSingleSelection>
            <ZoneDetailSlideIn
                {...p}
                portal={p.portal}
                title={
                    <Localized {...runningCheckinByPlateTexts.zoneDetails} />
                }
                open={p.state.open}
                onClose={() => {
                    p.setState({ open: false });
                }}
            />
        </div>
    );
});

const ZoneDetailSlideIn = portalSlideIn<
    ZoneInfoDetailProps & {
        onClose: () => void;
        open: boolean;
    }
>(p => {
    return (
        <div
            className={css({
                padding: '12px 24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            })}
        >
            <div>
                <LabeledText
                    label={fromOldNotation('City', texts)}
                >{`${p.zipCode} ${p.city}`}</LabeledText>

                <LabeledText
                    label={fromOldNotation('Zone', texts)}
                >{`${p.extZoneCode} ${p.name}`}</LabeledText>

                <LabeledText label={fromOldNotation('Operator', texts)}>
                    {p.operatorName}
                </LabeledText>

                <LabeledText label={fromOldNotation('StartDate', common)}>
                    {p.start.toFormat(LuxonDateStringFormats.DATE_HOUR_MINUTE)}
                </LabeledText>
            </div>
        </div>
    );
});

interface LicensePlateDetail {
    portal: SlideInPortalId;
    update: Flux.Updater;
    licensePlate: RenderableLicensePlate;
    showStartWithOtherPlate: boolean;
    language: string;
}

export const LicensePlateDetail = localState<
    LicensePlateDetail,
    { open: boolean }
>({ open: false }, p => {
    return (
        <div>
            <CompactSingleSelection
                context={InputContext.activeblue}
                onClick={() => {
                    p.setState({ open: true });
                    p.update(s =>
                        logAction(s, 'running-checkin-by-plate-open-lp-detail'),
                    );
                }}
                veryCompactThresholdHeight={Responsive.level3}
            >
                <LicensePlateEntityComp licensePlate={p.licensePlate} />
            </CompactSingleSelection>
            <LicensePlateDetailSlideIn
                {...p}
                portal={p.portal}
                title={<Localized {...runningCheckinByPlateTexts.vehicle} />}
                open={p.state.open}
                onClose={() => {
                    p.setState({ open: false });
                }}
            />
        </div>
    );
});

const LicensePlateDetailSlideIn = portalSlideIn<
    LicensePlateDetail & {
        onClose: () => void;
        open: boolean;
    }
>(p => {
    return (
        <div
            className={css({
                padding: '12px 24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            })}
        >
            <div>
                <LabeledText label={fromOldNotation('LicensePlate', texts)}>
                    {p.licensePlate.licensePlateNr}
                </LabeledText>

                <LabeledText
                    label={fromOldNotation('LicensePlateCountryCode', texts)}
                >
                    {p.licensePlate.countryId}
                </LabeledText>

                <LabeledText label={fromOldNotation('LicensePlateType', texts)}>
                    {getTypeString(p.language, p.licensePlate.type)}
                </LabeledText>

                {p.licensePlate.remarks && (
                    <LabeledText label={fromOldNotation('Remarks', texts)}>
                        {p.licensePlate.remarks}
                    </LabeledText>
                )}
            </div>
            {p.showStartWithOtherPlate && (
                <div>
                    <Clickable
                        element="div"
                        className={css({
                            ...Typo.body,
                            textDecoration: 'underline',
                            color: Colors.blue,
                        })}
                        onClick={() => {
                            p.update(
                                StartedCheckinByPlateOnThisDeviceState.clear,
                            );
                            p.update(s =>
                                logAction(
                                    s,
                                    'running-checkin-by-plate-start-with-other-plate-clicked',
                                ),
                            );
                        }}
                    >
                        <Localized
                            {...runningCheckinByPlateTexts.startWithOtherPlate}
                        />
                    </Clickable>
                </div>
            )}
        </div>
    );
});

export interface RunningCheckinByPlateProps {
    transaction: TransactionsListState.CheckinByPlateDetailState.RunningCheckinByPlate;
    portal: SlideInPortalId;
    isPrimaryTransactionInParkCreate: boolean;
}

export interface State {
    settings: SettingsState.State;
    parkTransactionStop: StopRequest.State;
    licensePlates: LicensePlateState.State;
}

export namespace Responsive {
    export const level1 = 540;
    export const level2 = 500;
    export const level3 = 461;
    export const level4 = 440;
    export const level2Media = `@media(max-height: ${level2}px)`;
    export const level4Media = `@media(max-height: ${level4}px)`;
}
