import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import { ButtonRow } from 'dg-web-shared/tb-ui/buttons/ButtonRow.tsx';
import { LabeledText } from '../../ui/typo/LabeledText.tsx';
import * as Http from '../../api/Http';
import * as SettingsState from '../../common/state/SettingsState';
import { Formatter } from '../../utils/Date';
import * as TransactionsListState from '../state/TransactionsListState';
import { stringToFourCharacterBlocks } from 'dg-web-shared/lib/StringConversions';
import { css } from '@emotion/css';
import { PriceDetailsInfoBox } from './TransactionsList';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../common/components/Localized';
import { useState } from 'react';
import infoButtonIconBits from '../../../assets/info_button.svg';

interface OffstreetCheckinProps {
    transaction: TransactionsListState.OffstreetCheckinDetail.OffstreetCheckin;
}

export function OffstreetCheckin(p: OffstreetCheckinProps) {
    const [showDetails, setShowDetails] = useState(false);
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    return (
        <div
            className={css({
                padding: '16px 24px',
            })}
        >
            <LabeledText
                label={{
                    de: 'Parking',
                    fr: 'Parking',
                    it: 'Parcheggio',
                    en: 'Parking',
                }}
            >
                {p.transaction.zoneName}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Ort',
                    fr: 'Lieu',
                    it: 'Località',
                    en: 'City',
                }}
            >
                {p.transaction.city}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Badge',
                    fr: 'Badge',
                    it: 'Badge',
                    en: 'Badge',
                }}
            >
                {`${stringToFourCharacterBlocks(p.transaction.badgeLabelNr)}${
                    p.transaction.remarks ? ` (${p.transaction.remarks})` : ''
                }`}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Beginn',
                    fr: 'Début',
                    it: 'Inizio',
                    en: 'Start',
                }}
            >
                {Formatter.dayMonthYearHourMinute(p.transaction.begin)}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Ende',
                    fr: 'Fin',
                    it: 'Fine',
                    en: 'End',
                }}
            >
                {Formatter.dayMonthYearHourMinute(p.transaction.end)}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Dauer',
                    fr: 'Durée',
                    it: 'Durata',
                    en: 'Duration',
                }}
            >
                {Formatter.getDuration(
                    p.transaction.begin,
                    p.transaction.end,
                    Formatter.durationTexts[storeState.settings.language],
                )}
            </LabeledText>
            <div className={css({ display: 'flex' })}>
                <LabeledText
                    label={{
                        de: 'Preis',
                        fr: 'Prix',
                        it: 'Prezzo',
                        en: 'Price',
                    }}
                >
                    {NumberFormatter.numberToPrice(p.transaction.price)}
                </LabeledText>
                {!!p.transaction.price && (
                    <a
                        className={css({
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            paddingLeft: '16px',
                            paddingBottom: '24px',
                            cursor: 'pointer',
                        })}
                        onClick={() => {
                            setShowDetails(true);
                        }}
                    >
                        <img src={infoButtonIconBits} />
                    </a>
                )}
                {showDetails && (
                    <PriceDetailsInfoBox
                        cost={p.transaction.cost}
                        onClose={() => setShowDetails(false)}
                    />
                )}
            </div>
            <LabeledText
                label={{
                    de: 'Betreiber',
                    fr: 'Exploitant',
                    it: 'Gestore',
                    en: 'Operator',
                }}
            >
                {p.transaction.operatorName}
            </LabeledText>
            <ButtonRow>
                <a
                    target="_blank"
                    href={Http.getContractReceiptPDFURL(
                        p.transaction.contractId.toString(),
                        p.transaction.key,
                    )}
                    rel="noreferrer"
                >
                    <BasicButton
                        negative={true}
                        label={
                            <Localized
                                de="Beleg anzeigen"
                                fr="Afficher document"
                                it="Mostra documento"
                                en="Show document"
                            />
                        }
                        onClick={() => {}}
                    />
                </a>
            </ButtonRow>
        </div>
    );
}
