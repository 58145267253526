import { css } from '@emotion/css';
import { DateTime, Duration } from 'luxon';

import * as superagent from 'superagent';
import { Slope } from 'dg-web-shared/common/tariff-logic/Tariff';
import {
    generateState,
    selectState,
    Store,
    Updater,
    useStore,
    useUpdate,
} from 'dg-web-shared/lib/Flux';
import { everyNewMinuteIntervalRenderer } from 'dg-web-shared/lib/IntervalRenderer';
import { Message } from 'dg-web-shared/lib/Localized';

import { languageFromString } from 'dg-web-shared/lib/Text';
import * as MetaServerState from '../../../account/meta/state/MetaServerState';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import {
    AddPaymentMethodParkCreateMenu,
    PaymentOriginType,
} from '../add-payment-method/AddPaymentMethodParkCreateMenu';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../../common/components/FullWidthBottomButton';
import { Localized } from '../../../common/components/Localized';
import {
    getFormattedDuration,
    ParkDurationWheel,
    WheelStyle,
} from '../../../common/components/ParkDurationWheel';
import { GeolocationState } from '../../../common/state/GeolocationState';
import * as SettingsState from '../../../common/state/SettingsState';
import { fromOldNotation } from '../../../Translate';
import { DEVICE_UUID, logAction } from '../../../utils/ActionLog';
import { Location } from 'dg-web-shared/common/utils/Geolocation';
import { onSuccessfulTransactionPurchase } from '../../actions/ZoneTransactionActions';
import { transactionPurchaseTexts } from '../../i18n/ParkCreateTexts';
import * as EntitySelectionState from '../../state/EntitySelectionState';
import * as ParkOptionListState from '../../state/ParkOptionListState';
import * as LicensePlateState from '../../../common/state/LicensePlateState';
import { LicensePlate } from '../../state/shared/EntityData';
import * as TransactionState from '../../state/zone-transaction/TransactionState';
import { LicensePlateSelectionTransaction } from '../license-plate/LicensePlateSelectionTransaction';
import {
    WhitelistRequest,
    WhitelistRequestPending,
} from '../permit/WhitelistRequest';
import { ZoneEnforcedCheckinResponsive } from './ZoneEnforcedCheckinResponsive';
import {
    RequestStatus,
    ServerRequestState,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    ArrowPosition,
    ButtonText,
    InlineErrorBox,
    InlineQuestionBox,
    ModalAlertBox,
    ModalErrorBox,
} from '../../../ui/modals/Confirmable';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { SavedCreditCardState } from '../../../account/shared/SavedCreditCardAliasState';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import { LabeledText } from '../../../ui/typo/LabeledText.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { NoParkingpayUseTwintBlock } from './NoParkingpayUseTwintAlert';
import { useTransactionListRefetch } from '../../../transactions-list/TransactionsListContext';
import { useServerFetch } from '../../../hooks/ServerStateHooks';
import { SpinnerModal } from '../../../ui/spinner/Spinner.tsx';
import { useEffect, useState } from 'react';

export const ZoneEnforcedCheckin = selectState<
    { zoneId: number },
    {
        settings: SettingsState.State;
        zone: TransactionState.ParkTransaction.State;
        meta: MetaServerState.State;
        licensePlateSelectionTransaction: EntitySelectionState.Selection.Transaction.State;
        licensePlates: LicensePlateState.State;
    }
>(
    (s, props) => {
        const context = {
            permitZoneId: props.zoneId,
        };
        return {
            zone: TransactionState.ParkTransaction.get(s, context),
            settings: new SettingsState.StateSlice(s).state,
            meta: new MetaServerState.StateSlice(s).state,
            licensePlateSelectionTransaction:
                EntitySelectionState.Selection.Transaction.get(s),
            licensePlates: new LicensePlateState.StateSlice(s).state,
        };
    },
    p => {
        useEffect(() => {
            if (p.licensePlates.data.length === 1) {
                p.update(s => {
                    EntitySelectionState.Selection.Transaction.stateWrite(s, {
                        selectedLicensePlate: p.licensePlates.data[0].id,
                    });

                    return 'autoSelect-licensePlate';
                });
            }
        }, [p.licensePlates.data.length]);

        const isGeneveOrLausanne =
            [499, 173].indexOf(p.zone.data?.operatorId ?? -1) > -1;

        if (p.zone.pending && !p.zone.data) {
            return (
                <SpinnerModal visible portal={SlideInPortalId.PARK_CREATE} />
            );
        }

        if (!p.zone.data) {
            return null;
        }

        return (
            <>
                <NoParkingpayUseTwintBlock show={isGeneveOrLausanne} />
                {!isGeneveOrLausanne && (
                    <LicensePlateSelectionTransaction
                        language={languageFromString(p.settings.language)}
                        licensePlates={p.zone.data.licensePlateData}
                        selectedId={
                            p.licensePlateSelectionTransaction
                                .selectedLicensePlate
                        }
                        update={p.update}
                        address={p.meta.data.address}
                        shippingAddress={p.meta.data.shippingAddress}
                        operatorName={p.zone.data.operatorName}
                        operatorContactDetails={
                            p.zone.data.operatorContactDetails
                        }
                        additionalPaymentProhibited={
                            p.zone.data.additionalPaymentProhibited
                        }
                    >
                        {p.licensePlateSelectionTransaction
                            .selectedLicensePlate && (
                            <ConfigAndPurchase
                                zone={p.zone.data}
                                language={p.settings.language}
                                selectedLp={
                                    p.zone.data.licensePlateData.filter(
                                        lp =>
                                            lp.id ===
                                            p.licensePlateSelectionTransaction
                                                .selectedLicensePlate,
                                    )[0]
                                }
                            />
                        )}
                    </LicensePlateSelectionTransaction>
                )}
            </>
        );
    },
);

interface ConfigProps {
    zone: TransactionState.ParkTransaction.Zone;
    selectedLp: LicensePlate;
    language: string;
}

const ConfigAndPurchase = (p: ConfigProps): JSX.Element | null => {
    if (p.selectedLp.mode === 'clearanceRequest') {
        return (
            <WhitelistRequest
                lps={[p.selectedLp]}
                customers={[]}
                refetchTypeData={() => null}
                isTransaction={true}
                operatorName={p.zone.operatorName}
                operatorContactDetails={p.zone.operatorContactDetails}
                permitTypeId={p.zone.clearancePermitTypeId as number}
                clearanceRequestCustomFields={
                    p.zone.clearanceRequestCustomFields
                }
            />
        );
    }
    if (p.selectedLp.mode === 'clearanceRequestPending') {
        return (
            <WhitelistRequestPending
                operatorName={p.zone.operatorName}
                operatorContactDetails={p.zone.operatorContactDetails}
            />
        );
    }
    return (
        <TimeSelectionWheelLayout
            zoneId={p.zone.permitZoneId}
            licensePlateId={p.selectedLp.id}
            operatorId={p.zone.operatorId}
        />
    );
};

export namespace CheckinDataState {
    export interface Context {
        intervalTimeStamp: DateTime;
        zoneId: number;
        licensePlateId: number;
        tick: number;
    }

    export interface SurchargeData {
        type: 'percent' | 'amount';
        value: number;
        digitalparkingVAT: boolean;
    }

    export interface CheckinData {
        success: true;
        tariffName: string | null;
        tariffMaxEnd: string;
        tariffMaxMinutes: number;
        tariffMaxTimeReason: TariffMaxTimeReason;
        isMaxDurationReducedByTwint: boolean;
        minChfStep: number;
        tariffCurve: Slope[];
        begin: string;
        surchargeData: SurchargeData[];
        isParkingProhibitedNowByTariff: boolean;
    }

    export enum ValidationError {
        ERR_ALREADY_LOGGED_IN = 'ERR_ALREADY_LOGGED_IN',
        ERR_NOT_ENOUGH_BALANCE_MIN = 'ERR_NOT_ENOUGH_BALANCE_MIN',
        ERR_NOT_ENOUGH_BALANCE_MIN_AND_RESTRICTED_LOGIN = 'ERR_NOT_ENOUGH_BALANCE_MIN_AND_RESTRICTED_LOGIN',
        ERR_TRANSACTION_NOT_ALLOWED = 'ERR_TRANSACTION_NOT_ALLOWED',
    }

    export enum TariffMaxTimeReason {
        NO_BALANCE = 'NO_BALANCE',
    }

    export interface CheckinDataValidationError {
        success: false;
        error: ValidationError;
    }

    export type State = ServerRequestState<
        CheckinData | CheckinDataValidationError
    >;

    export function use(context: Context): [State, () => void] {
        return useServerFetch<
            CheckinData | CheckinDataValidationError,
            Context,
            null
        >(
            {
                request: context =>
                    superagent.get(
                        `/ui-api/customer-account/zone-enforced-checkin-data/${context.zoneId}/${context.licensePlateId}`,
                    ),
            },
            context,
        );
    }
}

namespace CheckinWrite {
    export interface Arguments {
        zoneId: number;
        licensePlateId: number;
        durationInMinutes: number;
        location: Location | null;
        deviceUuid: string | null;
    }

    export type Error = null | {
        message: string;
    };

    export interface Success {
        id: number;
    }

    interface useParams {
        onSuccess: (id: number) => void;
        onError: (requestStatus: RequestStatus) => void;
    }

    export type State = ServerRequestState<
        CheckinWrite.Success,
        CheckinWrite.Error
    >;

    export function use({ onSuccess, onError }: useParams) {
        return useServerWrite<
            CheckinWrite.Arguments,
            CheckinWrite.Success,
            CheckinWrite.Error
        >({
            req(payload) {
                return superagent
                    .post('/ui-api/customer-account/zone-enforced-checkin')
                    .send(payload);
            },
            onResponse(res) {
                if (res.status === RequestStatus.SUCCESS) {
                    return onSuccess(res.data.id);
                }
                onError(res.status);
            },
        });
    }
}

export namespace TimeSelectionWheelTick {
    const getNextTick = () => Math.random();
    const state = generateState('time-selection-wheel-tick', {
        tick: getNextTick(),
    });

    export const get = state.get;
    export const tick = (s: Store) =>
        state.stateWrite(s, { tick: getNextTick() });
}

interface TimeSelectionWheelProps {
    zoneId: number;
    licensePlateId: number;
    operatorId: number;
}

namespace ParkDuration {
    export interface State {
        state: null | number;
        set: (updateParams: null | number) => void;
    }

    export function use() {
        const [parkDurationState, updateParkDurationState] = useState<
            null | number
        >(null);
        return {
            state: parkDurationState,
            set: updateParkDurationState,
        };
    }
}

const TimeSelectionWheelLayout =
    everyNewMinuteIntervalRenderer<TimeSelectionWheelProps>(
        ({ zoneId, licensePlateId, intervalTimeStamp }) => {
            const { storeState, update } = useStore(s => {
                return {
                    geolocation: GeolocationState.get(s),
                    tick: TimeSelectionWheelTick.get(s),
                };
            });
            const refetchTransactionList = useTransactionListRefetch();
            const [currentLogin] = CurrentLoginState.use();

            function log(message: string) {
                update(s => logAction(s, message));
            }

            const [checkinWriteState, checkinWriteSubmit, checkinWriteReset] =
                CheckinWrite.use({
                    onSuccess(id: number) {
                        update(s => {
                            ParkOptionListState.Selection.setVariant(s, null);
                            refetchTransactionList();
                            onSuccessfulTransactionPurchase(s, id);
                            return 'purchase-transaction-post-callback';
                        });
                    },
                    onError(status: RequestStatus) {
                        update(s =>
                            logAction(
                                s,
                                'purchase-transaction-post-failed',
                                status,
                            ),
                        );
                    },
                });

            const parkDuration = ParkDuration.use();

            const [checkinData] = CheckinDataState.use({
                zoneId,
                intervalTimeStamp,
                licensePlateId,
                tick: storeState.tick.tick,
            });
            const checkinWrite = {
                state: checkinWriteState,
                submit: () => {
                    const payload = {
                        zoneId,
                        licensePlateId,
                        durationInMinutes:
                            parkDuration.state !== null
                                ? Math.ceil(parkDuration.state)
                                : 0,
                        location: storeState.geolocation.currentLocation,
                        deviceUuid: DEVICE_UUID,
                    };
                    update(s =>
                        logAction(s, 'purchase-transaction-submit', payload),
                    );
                    checkinWriteSubmit(payload);
                },
                reset: checkinWriteReset,
            };
            const [showTopUpSlideIn, setShowTopUpSlideIn] = useState(false);
            return (
                <>
                    <SpinnerModal
                        visible={
                            checkinWrite.state.status === 'pending' ||
                            (checkinData.status === RequestStatus.PENDING &&
                                !checkinData.data)
                        }
                        portal={SlideInPortalId.PARK_CREATE}
                    />
                    <TimeSelectionWheel
                        {...{ checkinData }}
                        {...{ checkinWrite }}
                        {...{ parkDuration }}
                        {...{ log }}
                        isAdmin={
                            currentLogin.data?.loginRole ===
                            CurrentLoginState.LoginRole.ADMIN
                        }
                        update={update}
                        setShowTopUpSlideIn={(v: boolean) => {
                            setShowTopUpSlideIn(v);
                            if (v) {
                                update(s =>
                                    logAction(
                                        s,
                                        'show-payment-methods-from-checkin',
                                    ),
                                );
                            }
                        }}
                    />
                    <AddPaymentMethodParkCreateMenu
                        paymentOriginInfo={{
                            type: PaymentOriginType.CHECKIN_BY_PLATE,
                        }}
                        open={showTopUpSlideIn}
                        onClose={() => {
                            update(TimeSelectionWheelTick.tick);
                            setShowTopUpSlideIn(false);
                        }}
                    />
                </>
            );
        },
    );

interface Props {
    checkinData: CheckinDataState.State;
    checkinWrite: {
        state: CheckinWrite.State;
        reset: () => void;
        submit: () => void;
    };
    parkDuration: ParkDuration.State;
    setShowTopUpSlideIn: (value: boolean) => void;
    update: Updater;
    isAdmin: boolean;
}

function TimeSelectionWheel({
    checkinData,
    checkinWrite,
    update,
    parkDuration,
    setShowTopUpSlideIn,
    isAdmin,
}: Props) {
    const [
        increaseDurationByTopupWarningClickedAway,
        setIncreaseDurationByTopupWarningClickedAway,
    ] = useState(false);

    function log(message: string) {
        update(s => logAction(s, message));
    }

    if (
        checkinData.status === RequestStatus.PENDING ||
        checkinData.status === RequestStatus.NEVER_EXECUTED
    ) {
        return <SpinnerModal visible portal={SlideInPortalId.PARK_CREATE} />;
    } else if (checkinData.status === RequestStatus.ERROR) {
        return <Error status={checkinData.status} />;
    }

    if (!checkinData.data.success) {
        if (
            checkinData.data.error ===
            CheckinDataState.ValidationError.ERR_NOT_ENOUGH_BALANCE_MIN
        ) {
            return (
                <TopUpWarning
                    isAdmin={isAdmin}
                    onCancel={() =>
                        update(ParkOptionListState.Selection.setVariant, null)
                    }
                    toTopUp={() => setShowTopUpSlideIn(true)}
                />
            );
        }
        return <ValidationError validationError={checkinData.data.error} />;
    }

    if (checkinData.data.isParkingProhibitedNowByTariff) {
        return <ParkingProhibited />;
    }

    const balanceIsTheReasonForMaxTime =
        checkinData.data.tariffMaxTimeReason ===
        CheckinDataState.TariffMaxTimeReason.NO_BALANCE;

    if (
        balanceIsTheReasonForMaxTime &&
        !increaseDurationByTopupWarningClickedAway
    ) {
        return (
            <IncreaseDurationByTopUpWarning
                tariffMaxMinutes={checkinData.data.tariffMaxMinutes}
                onContinue={() =>
                    setIncreaseDurationByTopupWarningClickedAway(true)
                }
                toTopUp={() => setShowTopUpSlideIn(true)}
                isAdmin={isAdmin}
            />
        );
    }
    return (
        <WheelAndButton
            {...{ checkinData }}
            {...{ checkinWrite }}
            {...{ parkDuration }}
            {...{ log, setShowTopUpSlideIn }}
        />
    );
}

function ParkingProhibited() {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Parkverbot"
                    fr="Interdiction de stationnement"
                    it="Divieto di parcheggio"
                    en="Parking prohibited"
                />
            }
        >
            <p>
                <Localized
                    de="Zurzeit darf in dieser Zone mit diesem Fahrzeug nicht parkiert werden."
                    fr="Actuellement, il est interdit de se garer dans cette zone avec ce véhicule."
                    it="Attualmente non è permesso parcheggiare questo veicolo in questa zona."
                    en="Currently it's not allowed to park in this zone with this vehicle."
                />
            </p>
        </InlineErrorBox>
    );
}

namespace ShowSelectTimeModal {
    export interface State {
        isShown: boolean;
        show: () => void;
        hide: () => void;
    }

    export function use() {
        const [showSelectTimeModalState, updateShowSelectTimeModal] =
            useState(false);

        return {
            isShown: showSelectTimeModalState,
            hide: () => updateShowSelectTimeModal(false),
            show: () => updateShowSelectTimeModal(true),
        };
    }
}

interface WheelProps {
    checkinData: CheckinDataState.State;
    checkinWrite: {
        state: CheckinWrite.State;
        reset: () => void;
        submit: () => void;
    };
    parkDuration: ParkDuration.State;
    setShowTopUpSlideIn: (value: boolean) => void;
    log: (message: string) => void;
}

function WheelAndButton({
    checkinData,
    checkinWrite,
    parkDuration,
    setShowTopUpSlideIn,
    log,
}: WheelProps) {
    const update = useUpdate();
    const showSelectTimeModal = ShowSelectTimeModal.use();

    const start = DateTime.local().set({
        second: 0,
        millisecond: 0,
    });

    if (checkinWrite.state.status === 'error') {
        if (checkinWrite.state.data?.message === 'EXTERNAL_SERVICE_FAILURE') {
            return (
                <CheckInPostFailedExternallyAlertBox
                    onClose={checkinWrite.reset}
                />
            );
        } else if (checkinWrite.state.data?.message === 'ALREADY_RUNNING') {
            <ModalErrorBox
                confirmCallback={checkinWrite.reset}
                titleCaption={<Localized {...transactionAlreadyRunning} />}
            >
                <p>
                    <Localized
                        de="Für das gewählte Kennzeichen wurde in dieser Zone bereits ein Parkvorgang gestartet. Dieser muss beendet werden, bevor ein neuer gestartet werden kann."
                        fr="Pour cette plaque d'immatriculation il y a déjà une procédure de stationnement en cours dans la zone sélectionnée. Il faut arrêter cette procédure avant de pouvoir en commencer une nouvelle."
                        it="Per questo numero di targa è già in corso una procedura di parcheggio nella zona selezionata. Questa procedura dev'essere terminata prima di poterne avviare una nuova."
                        en="It was not possible to start a new parking procedure. A parking procedure for the selected license plate is already running in this zone."
                    />
                </p>
            </ModalErrorBox>;
        } else if (
            checkinWrite.state.data?.message ===
            'PAYMENT_ATTEMPT_AUTHORIZATION_FAILED'
        ) {
            return (
                <CheckInPostFailedPaymentAuthFailed
                    onClose={() => {
                        update(TimeSelectionWheelTick.tick);
                        checkinWrite.reset();
                    }}
                />
            );
        } else if (
            checkinWrite.state.data?.message ===
            'PAYMENT_ATTEMPT_AUTHORIZATION_EXCEPTION'
        ) {
            return (
                <CheckInPostFailedPaymentAuthException
                    onClose={() => {
                        update(TimeSelectionWheelTick.tick);
                        checkinWrite.reset();
                    }}
                />
            );
        } else if (
            checkinWrite.state.data?.message === 'PAYMENT_ATTEMPT_INVALID_ALIAS'
        ) {
            return (
                <CheckInPostFailedPaymentAuthInvalidAlias
                    onClose={() => {
                        update(SavedCreditCardState.refetch);
                        update(s => {
                            new PaymentTypeState.StateSlice(s).reset();
                            return 'reset-payment-type-state';
                        });
                        update(TimeSelectionWheelTick.tick);
                        checkinWrite.reset();
                        setShowTopUpSlideIn(true);
                    }}
                />
            );
        } else {
            return (
                <CheckInPostFailedAlertBox onClose={() => location.reload()} />
            );
        }
    }

    if (
        !checkinData.data ||
        checkinData.status !== RequestStatus.SUCCESS ||
        !checkinData.data.success
    ) {
        return null;
    }

    if (showSelectTimeModal.isShown) {
        return (
            <NoDurationSelectedAlertBox onClose={showSelectTimeModal.hide} />
        );
    }

    return (
        <>
            {checkinData.data.tariffName ? (
                <LabeledText
                    compactHeight={ZoneEnforcedCheckinResponsive.level4}
                    superCompactHeight={ZoneEnforcedCheckinResponsive.level1}
                    context={InputContext.inverted}
                    label={{
                        de: 'Tarif',
                        fr: 'Tarif',
                        it: 'Tariffa',
                        en: 'Tariff',
                    }}
                >
                    {checkinData.data.tariffName}
                </LabeledText>
            ) : null}
            <ParkDurationWheel
                exactMinutesWithFraction={parkDuration.state}
                tariffName={checkinData.data.tariffName}
                tariffCurve={checkinData.data.tariffCurve}
                maxMinutes={checkinData.data.tariffMaxMinutes}
                minChfStep={checkinData.data.minChfStep}
                setMin={() => {
                    parkDuration.set(0);
                    log('park-duration-wheel-set-quick-min');
                }}
                setMax={() => {
                    if (checkinData.data && checkinData.data.success) {
                        parkDuration.set(checkinData.data.tariffMaxMinutes);
                        log('park-duration-wheel-set-quick-max');
                    }
                }}
                currentTimeRef={start}
                minMinutes={0}
                setMinutes={m => {
                    parkDuration.set(m);
                }}
                start={start}
                compactHeight={ZoneEnforcedCheckinResponsive.level3}
                surchargeData={checkinData.data.surchargeData}
                style={WheelStyle.parkingpay_inverted}
                isMaxDurationReducedByTwint={
                    checkinData.data.isMaxDurationReducedByTwint
                }
            />
            <CheckinButton
                {...{ checkinWrite }}
                {...{ parkDuration }}
                {...{ showSelectTimeModal }}
                {...{ log }}
            />
        </>
    );
}

interface CheckinButtonProps {
    checkinWrite: {
        state: CheckinWrite.State;
        reset: () => void;
        submit: () => void;
    };
    parkDuration: ParkDuration.State;
    log: (message: string) => void;
    showSelectTimeModal: ShowSelectTimeModal.State;
}

function CheckinButton({
    checkinWrite,
    parkDuration,
    showSelectTimeModal,
    log,
}: CheckinButtonProps) {
    return (
        <div className={css({ paddingBottom: '40px', flexShrink: 0 })}>
            <FullWidthBottomButton
                color={FullWidthBottomButtonColor.GREEN}
                disabled={
                    !parkDuration.state ||
                    checkinWrite.state.status === RequestStatus.PENDING
                }
                allowClickOnDisabled={true}
                onClick={() => {
                    if (parkDuration.state) {
                        checkinWrite.submit();
                    } else {
                        log(
                            'clicked-on-start-transaction-without-selecting-time',
                        );
                        showSelectTimeModal.show();
                    }
                }}
                compactThresholdLength={ZoneEnforcedCheckinResponsive.level1}
                label={
                    checkinWrite.state.status === RequestStatus.PENDING
                        ? {
                              de: 'wird gestartet…',
                              fr: 'démarrage en cours…',
                              it: 'avvio in corso…',
                              en: 'starting…',
                          }
                        : {
                              de: 'Parkvorgang starten',
                              fr: 'Démarrer la procédure',
                              it: 'Avvia procedura',
                              en: 'Start',
                          }
                }
            />
        </div>
    );
}

const errorTextFromValidationError = (
    error:
        | CheckinDataState.ValidationError.ERR_ALREADY_LOGGED_IN
        | CheckinDataState.ValidationError.ERR_NOT_ENOUGH_BALANCE_MIN_AND_RESTRICTED_LOGIN
        | CheckinDataState.ValidationError.ERR_TRANSACTION_NOT_ALLOWED
        | null,
): { header: Message; body: Message } => {
    switch (error) {
        case CheckinDataState.ValidationError
            .ERR_NOT_ENOUGH_BALANCE_MIN_AND_RESTRICTED_LOGIN:
            return {
                header: {
                    de: 'Saldo zu tief',
                    fr: 'Solde insuffisant',
                    it: 'Saldo insufficiente',
                    en: 'Balance too low',
                },
                body: {
                    de: 'Der Saldo ist derzeit nicht ausreichend, um einen Parkvorgang in der ausgewählten Zone zu starten. Bitte wenden Sie sich an den Administrator Ihres Parkingpay-Kontos.',
                    fr: 'Le solde du compte est actuellement insuffisant pour démarrer une procédure dans la zone sélectionnée. Veuillez contacter votre administrateur de compte Parkingpay.',
                    it: "Il saldo del conto è al momento insufficiente per poter avviare una procedura nella zona selezionata. Voglia contattare l'amministratore del vostro conto Parkingpay.",
                    en: 'The account balance is currently insufficient to a procedure in the selected zone. Please contact your Parkingpay account administrator.',
                },
            };

        case CheckinDataState.ValidationError.ERR_ALREADY_LOGGED_IN:
            return {
                header: transactionAlreadyRunning,
                body: fromOldNotation(
                    'ErrorAlreadyLoggedIn',
                    transactionPurchaseTexts,
                ),
            };
        case CheckinDataState.ValidationError.ERR_TRANSACTION_NOT_ALLOWED:
            return {
                header: {
                    de: 'Parkvorgang nicht möglich',
                    fr: 'Procédure non autorisée',
                    it: 'Procedura non consentita',
                    en: 'Procedure not allowed',
                },
                body: fromOldNotation(
                    'ErrorTransNotPossible',
                    transactionPurchaseTexts,
                ),
            };
        default:
            return {
                header: {
                    de: 'Fehler',
                    fr: 'Erreur',
                    it: 'Errore',
                    en: 'Error',
                },
                body: fromOldNotation(
                    'TransactionErrorGeneric',
                    transactionPurchaseTexts,
                ),
            };
    }
};

function Error({ status }: { status: RequestStatus }) {
    return (
        <InlineErrorBox titleCaption={`HTTP ${status}`}>
            <p>
                <Localized
                    de="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk."
                    fr="Une erreur est survenue. Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk."
                    it="Si è verificato un errore. Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk."
                    en="An error has occurred. Please try again later. If the problem persists, please contact our Helpdesk."
                />
            </p>
        </InlineErrorBox>
    );
}

function ValidationError({
    validationError,
}: {
    validationError:
        | CheckinDataState.ValidationError.ERR_ALREADY_LOGGED_IN
        | CheckinDataState.ValidationError.ERR_NOT_ENOUGH_BALANCE_MIN_AND_RESTRICTED_LOGIN
        | CheckinDataState.ValidationError.ERR_TRANSACTION_NOT_ALLOWED
        | null;
}) {
    const text = errorTextFromValidationError(validationError);

    return (
        <InlineErrorBox
            titleCaption={<Localized {...text.header} />}
            arrowPosition={ArrowPosition.left}
        >
            <p>
                <Localized {...text.body} />
            </p>
        </InlineErrorBox>
    );
}

function TopUpWarning({
    onCancel,
    toTopUp,
    isAdmin,
}: {
    onCancel: () => void;
    toTopUp: () => void;
    isAdmin: boolean;
}) {
    const questionActions = isAdmin
        ? {
              confirmCaption: ButtonText.EDIT,
              confirmCallback: toTopUp,
              cancelCaption: ButtonText.CANCEL,
              cancelCallback: onCancel,
          }
        : {
              confirmCaption: ButtonText.CANCEL,
              confirmCallback: onCancel,
          };

    return (
        <InlineQuestionBox
            {...questionActions}
            titleCaption={
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Means of payment"
                />
            }
        >
            <Localized
                de={
                    <>
                        <p>
                            Ohne gültiges Zahlungsmittel können Sie in dieser
                            Zone nicht parkieren.
                        </p>
                        {isAdmin && (
                            <p>
                                Um ein Zahlungsmittel zu definieren, wählen Sie
                                „ÄNDERN“ aus.
                            </p>
                        )}
                    </>
                }
                fr={
                    <>
                        <p>
                            Sans moyen de paiement, vous ne pouvez pas vous
                            stationner dans cette zone.
                        </p>
                        {isAdmin && (
                            <p>Pour en définir un, sélectionnez «MODIFIER».</p>
                        )}
                    </>
                }
                it={
                    <>
                        <p>
                            Senza un mezzo di pagamento non può parcheggiare in
                            questa zona.
                        </p>
                        {isAdmin && (
                            <p>Per definirne uno, selezioni «MODIFICA».</p>
                        )}
                    </>
                }
                en={
                    <>
                        <p>
                            Without a means of payment, you cannot park in this
                            zone.
                        </p>
                        {isAdmin && <p>To define one, select «MODIFY».</p>}
                    </>
                }
            />
        </InlineQuestionBox>
    );
}

function IncreaseDurationByTopUpWarning({
    tariffMaxMinutes,
    onContinue,
    toTopUp,
    isAdmin,
}: {
    tariffMaxMinutes: number;
    onContinue: () => void;
    toTopUp: () => void;
    isAdmin: boolean;
}) {
    const questionActions = isAdmin
        ? {
              confirmCaption: ButtonText.EDIT,
              confirmCallback: toTopUp,
              cancelCaption: ButtonText.CONTINUE,
              cancelCallback: onContinue,
          }
        : {
              confirmCaption: ButtonText.CONTINUE,
              confirmCallback: onContinue,
          };

    const maxDuration = getFormattedDuration(
        Duration.fromObject({ minute: tariffMaxMinutes }).shiftTo(
            'hours',
            'minutes',
        ),
    );

    return (
        <InlineQuestionBox
            {...questionActions}
            titleCaption={
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Means of payment"
                />
            }
        >
            <Localized
                de={
                    <>
                        <p>
                            Mit dem aktuellen Saldo können Sie in dieser Zone{' '}
                            <b>max. {maxDuration}</b> parkieren.
                        </p>
                        {isAdmin && (
                            <p>
                                Falls Sie länger parkieren möchten, wählen Sie
                                „ÄNDERN“ aus.
                            </p>
                        )}
                    </>
                }
                fr={
                    <>
                        <p>
                            Avec votre solde actuel, vous pouvez stationner dans
                            cette zone pour une{' '}
                            <b>durée maximale de {maxDuration}</b>.
                        </p>
                        {isAdmin && (
                            <p>
                                Si vous souhaitez stationner plus longtemps,
                                sélectionnez «MODIFIER».
                            </p>
                        )}
                    </>
                }
                it={
                    <>
                        <p>
                            Con il saldo attuale può parcheggiare in questa zona{' '}
                            <b>al massimo {maxDuration}</b>.
                        </p>
                        {isAdmin && (
                            <p>
                                Se vuole parcheggiare più a lungo, selezioni
                                «MODIFICA».
                            </p>
                        )}
                    </>
                }
                en={
                    <>
                        <p>
                            With your current balance you can park in this zone
                            for <b>up to {maxDuration}</b>.
                        </p>
                        {isAdmin && (
                            <p>If you want to park longer, select «MODIFY».</p>
                        )}
                    </>
                }
            />
        </InlineQuestionBox>
    );
}

interface AlertBoxProps {
    onClose: () => void;
}

function NoDurationSelectedAlertBox({ onClose }: AlertBoxProps) {
    return (
        <ModalAlertBox
            confirmCallback={onClose}
            titleCaption={
                <Localized
                    de="Parkdauer auswählen"
                    fr="Sélectionner la durée"
                    it="Selezionare la durata"
                    en="Select parking time"
                />
            }
        >
            <p>
                <Localized
                    de="Bitte wählen Sie erst die voraussichtliche Parkdauer aus, indem Sie mit dem Finger das Rad bewegen."
                    fr="Veuillez d'abord sélectionner la durée de stationnement estimé en déplaçant la roue avec votre doigt."
                    it="Voglia prima selezionare la durata di parcheggio prevista, muovendo la rotella con un dito."
                    en="Please select the estimated parking time by moving the wheel with your finger."
                />
            </p>
            <p>
                <Localized
                    de="Danach können Sie den Parkvorgang jederzeit stoppen und nur die effektive Parkzeit bezahlen."
                    fr="Ensuite, vous pouvez arrêter la procédure de stationnement à tout moment, en ne payant que la durée effective."
                    it="Successivamente sarà possibile interrompere la procedura di parcheggio in qualsiasi momento, pagando solo il tempo effettivo."
                    en="You can terminate the transaction at any time later, paying only the effective time."
                />
            </p>
        </ModalAlertBox>
    );
}

function CheckInPostFailedExternallyAlertBox({ onClose }: AlertBoxProps) {
    return (
        <ModalErrorBox confirmCallback={onClose} titleCaption={notStarted}>
            <p>
                <Localized
                    de="Aufgrund eines technischen Problems mit einem externen System konnte der Parkvorgang nicht gestartet werden."
                    fr="En raison d'un problème technique avec un système externe, la procédure de stationnement n'a pas pu être demarrée."
                    it="A causa di un problema tecnico con un sistema esterno, non è stato possibile avviare la procedura di parcheggio."
                    en="Due to a technical problem with an external system, the parking procedure could not be started."
                />
            </p>
            {pleaseTryAgain}
        </ModalErrorBox>
    );
}

export function CheckInPostFailedPaymentAuthException({
    onClose,
}: AlertBoxProps) {
    return (
        <ModalErrorBox confirmCallback={onClose} titleCaption={notStarted}>
            <p>
                <Localized
                    de="Aufgrund einer technischen Störung beim Zahlungslösungsanbieter, konnte der Parkvorgang nicht gestartet werden."
                    fr="En raison d'un problème technique chez le fournisseur de la solution de paiement, la procédure de stationnement n'a pas pu être démarrée."
                    it="A causa di un problema tecnico presso il fornitore della soluzione di pagamento, non è stato possibile avviare la procedura di parcheggio."
                    en="Due to a technical disruption by the payment solution provider, the parking procedure could not be started."
                />
            </p>
            {pleaseTryAgain}
        </ModalErrorBox>
    );
}

export function CheckInPostFailedPaymentAuthFailed({ onClose }: AlertBoxProps) {
    return (
        <ModalErrorBox confirmCallback={onClose} titleCaption={notStarted}>
            <CheckinPostFailedPaymentAuthFailedBody />
        </ModalErrorBox>
    );
}

export function CheckinPostFailedPaymentAuthFailedBody() {
    return (
        <>
            <p>
                <CouldNotBeStarted />
            </p>
            <p>
                <Localized
                    de="Der Herausgeber des registrierten Zahlungsmittels hat die Reservierung des Betrags abgelehnt."
                    fr="L'émetteur du moyen de paiement enregistré a refusé de réserver le montant."
                    it="L'emittente del mezzo di pagamento registrato ha rifiutato di prenotare l'importo."
                    en="The issuer of the registered means of payment has refused to reserve the amount."
                />
            </p>
        </>
    );
}

const CouldNotBeStarted = () => (
    <Localized
        de="Der Parvorgang konnte nicht gestartet werden."
        fr="La procèdure de stationnement n'a pas pu être déclenché."
        it="La procedura di parcheggio non ha potuto essere avviata."
        en="The park transaction could not be started."
    />
);

const CheckInPostFailedPaymentAuthInvalidAlias = ({
    onClose,
}: AlertBoxProps) => {
    return (
        <ModalErrorBox confirmCallback={onClose} titleCaption={notStarted}>
            <p>
                <CouldNotBeStarted />
            </p>
            <p>
                <Localized
                    de="Der Betrag konnte nicht reserviert werden, da die Registrierung bei TWINT nicht mehr aktiv ist."
                    fr="Le montant n'a pas pu être réservé car l'enregistrement chez TWINT n'est plus actif."
                    it="L'importo non ha potuto essere prenotato in quanto la registrazione presso TWINT non è più attiva."
                    en="The amount could not be pre-booked as the registration at TWINT is no longer active."
                />
            </p>
            <p>
                <Localized
                    de="Beim nächsten Schritt TWINT auswählen, um sie wieder zu aktivieren."
                    fr="Dans l'étape suivante, sélectionnez TWINT pour l'activer à nouveau."
                    it="Al passo successivo, selezionare TWINT per attivarlo nuovamente."
                    en="In the next step, select TWINT to activate it again."
                />
            </p>
        </ModalErrorBox>
    );
};

function CheckInPostFailedAlertBox({ onClose }: AlertBoxProps) {
    return (
        <ModalErrorBox
            confirmCallback={onClose}
            titleCaption={connectionProblem}
        >
            <p>
                <Localized
                    de="Aufgrund eines technischen Problems war es nicht möglich, eine Bestätigung vom Server zu erhalten."
                    fr="En raison d'un problème technique, il n'a pas été possible d'obtenir une confirmation du serveur."
                    it="A causa di un problem tecnico, non è stato possibile ottenere una conferma dal server."
                    en="Due to a technical problem, it was not possible to get a confirmation from the server."
                />
                <Localized
                    de="Bevor Sie es erneut versuchen, prüfen Sie unter Transaktionen, ob der Parkvorgang gestartet werden konnte."
                    fr="Avant de réessayer, vérifiez dans Transactions si la procédure de stationnement a pu être lancée."
                    it="Prima di riprovare, controlli sotto Transazioni se la procedura di parcheggio ha potuto essere avviata."
                    en="Before you try again, check under Transactions whether the parking process could be started."
                />
            </p>

            <p>
                <Localized
                    de="Wenn das Problem weiterhin besteht, müssen Sie die Parkgebühr auf andere Weise bezahlen."
                    fr="Si le problème persiste, vous devrez payer les frais de stationnement par un autre moyen."
                    it="Se il problema persiste, dovrà procedere al pagamento della tassa di parcheggio tramite un altro mezzo."
                    en="If the problem persists, you will have to pay the parking fee by another means."
                />
            </p>
        </ModalErrorBox>
    );
}

const pleaseTryAgain = (
    <p>
        <Localized
            de="Bitte versuchen Sie es in ein paar Minuten erneut. Wenn das Problem weiterhin besteht, müssen Sie die Parkgebühr auf andere Weise bezahlen."
            fr="Veuillez réessayer dans quelques minutes. Si le problème persiste, vous devrez payer les frais de stationnement par un autre moyen."
            it="Voglia riprovare tra qualche minuto. Se il problema persiste, dovrà procedere al pagamento della tassa di parcheggio tramite un altro mezzo."
            en="Please try again in a few minutes. If the problem persists, you will have to pay the parking fee by another means."
        />
    </p>
);

const notStarted = (
    <Localized
        de="NICHT GESTARTET"
        fr="PAS DÉMARRÉE"
        it="NON AVVIATA"
        en="NOT STARTED"
    />
);

const connectionProblem = (
    <Localized
        de="VERBINDUNGSPROBLEM"
        fr="PROBLÈME DE CONNEXION"
        it="PROBLEMA DI CONNESSIONE"
        en="CONNECTION PROBLEM"
    />
);

const transactionAlreadyRunning = {
    de: 'Parkvorgang schon aktiv',
    fr: 'Procédure déjà active',
    it: 'Procedura già attiva',
    en: 'Procedure already running',
};
