export enum Language {
    DE = 'de',
    FR = 'fr',
    IT = 'it',
    EN = 'en',
}

export type Message = { [key in Language]: string };
export type MessageTemplate<P> = (props: P) => Message;
export type JsxMessage = { [key in Language]: JSX.Element };
export type JsxMessageTemplate<P> = (props: P) => JsxMessage;

export function languageFromString(s: string): Language {
    // If we pass default navigator language codes, we have to remove the country codes they might contain
    const normalizedLang = s.split('-')[0].toLowerCase();

    switch (normalizedLang) {
        case 'fr':
            return Language.FR;
        case 'it':
            return Language.IT;
        case 'en':
            return Language.EN;
        default:
            return Language.DE;
    }
}

export function makeLocalizedText(language: Language) {
    return function (message: Message): string {
        return message[language];
    };
}
