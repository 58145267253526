import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { LeftColumn, StaticPage } from './StaticPage.tsx';
import { Localized } from '../../../common/components/Localized.tsx';
import { Navigate, useParams } from 'react-router-dom';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function PayStationReceiptPage() {
    const { payStationPaymentId } = useParams();
    const { store } = useStore(() => null);

    if (!payStationPaymentId) {
        return <Navigate to={'/'} />;
    }

    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <a
                    href={`/ui-api/pay-station/payment/${payStationPaymentId}/pdf`}
                    target="_blank"
                    rel="noreferrer"
                    className={css({
                        ...Typo.body,
                    })}
                >
                    <Localized
                        de="Quittung herunterladen"
                        fr="Télécharger le reçu"
                        it="Scarica la ricevuta"
                        en="Download receipt"
                    />
                </a>
            </LeftColumn>
        </StaticPage>
    );
}
