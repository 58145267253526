import { TicketApprovalRequired } from './TicketApprovalRequired';
import { TicketApproved } from './TicketApproved';
import { Ticket } from './TicketSlideIn';
import { TicketSuccessDetails } from './TicketSuccessDetails';
import { TicketState } from '../../common/components/ticketplus/TicketPlusTypes';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../account/root/components/PortalSlidein';
import { useState } from 'react';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingpayAsyncLoadedSection } from '../../common/components/ParkingpayAsyncLoadedSection';
import { TicketError } from './ParkCreateTicketPlus';
import { TransactionSlideIn } from '../components/ParkVariantSelection';
import {
    TicketPlusTitle,
    ZoneInfoSlideInTitle,
} from '../../common/components/ticketplus/TicketPlusGenericTexts';
import { PayStationPaymentReceiptView } from '../../common/components/ticketplus/PayStationPaymentReceiptView.tsx';
import { QuickCheckoutLayout } from '../../quickcheckout/common/QuickCheckoutLayout.tsx';

export function SharedTicketSlideIn({
    portal,
    onClose,
    ticketState,
    refetchTicketState,
    onTicketReset,
    onTicketApproveSuccess,
    renderError,
}: {
    portal: SlideInPortalId;
    onClose: () => void;
    ticketState: ServerRequestState<Ticket, TicketError>;
    refetchTicketState: () => void;
    onTicketReset: () => void;
    onTicketApproveSuccess: () => void;
    renderError?: (error: TicketError | null) => JSX.Element | null;
}) {
    const [openZoneInfoSlideIn, setOpenZoneInfoSlideIn] = useState(false);
    return (
        <>
            <ParkingpayAsyncLoadedSection
                state={ticketState}
                renderSuccess={ticketData => (
                    <>
                        <TicketFlowSlideIn
                            portal={portal}
                            open
                            onClose={onClose}
                            title={<TicketPlusTitle />}
                            headerColor={
                                ticketState.status === RequestStatus.SUCCESS &&
                                ticketState.data.state === TicketState.BOOKED
                                    ? 'lightBlue'
                                    : 'darkBlue'
                            }
                            ticketData={ticketData}
                            refetchTicketState={refetchTicketState}
                            onTicketReset={onTicketReset}
                            onTicketApproveSuccess={onTicketApproveSuccess}
                            openZoneInfo={() => setOpenZoneInfoSlideIn(true)}
                        />
                        <TransactionSlideIn
                            headerColor="darkBlue"
                            title={<ZoneInfoSlideInTitle />}
                            onClose={() => setOpenZoneInfoSlideIn(false)}
                            portal={portal}
                            open={openZoneInfoSlideIn}
                            selectedZone={ticketData.zone}
                            isOffstreet={true}
                        />
                    </>
                )}
                renderError={renderError}
                portal={portal}
            />
        </>
    );
}

const TicketFlowSlideIn = portalSlideIn<TicketFlowProps>(TicketFlowContent);

type TicketFlowProps = {
    ticketData: Ticket;
    refetchTicketState: () => void;
    onTicketReset: () => void;
    onTicketApproveSuccess: () => void;
    openZoneInfo: () => void;
};

function TicketFlowContent({
    ticketData,
    refetchTicketState,
    onTicketReset,
    onTicketApproveSuccess,
    openZoneInfo,
}: TicketFlowProps) {
    if (ticketData.committedPayStationPaymentIds.length > 0) {
        return (
            <QuickCheckoutLayout>
                <PayStationPaymentReceiptView
                    committedPayStationPaymentIds={
                        ticketData.committedPayStationPaymentIds
                    }
                />
            </QuickCheckoutLayout>
        );
    }

    switch (ticketData.state) {
        case TicketState.CREATED:
        case TicketState.SCANNED:
        case TicketState.ALIAS_PENDING:
            return (
                <TicketApprovalRequired
                    ticketData={ticketData}
                    refetchTicketState={refetchTicketState}
                    onTicketApproveSuccess={onTicketApproveSuccess}
                    openZoneInfo={openZoneInfo}
                />
            );
        case TicketState.APPROVED:
            return (
                <TicketApproved
                    ticketData={ticketData}
                    onTicketReset={onTicketReset}
                    refetchTicketState={refetchTicketState}
                    openZoneInfo={openZoneInfo}
                />
            );
        case TicketState.BOOKED:
            return <TicketSuccessDetails ticketData={ticketData} />;
    }
}
