import { useEffect, useState } from 'react';
import { useServerWrite } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { TicketPlusClaimed } from '../../common/components/ticketplus/TicketPlusClaimed';
import { QuickCheckoutFlowTicketApproved } from './QuickCheckoutFlowTicketApproved';
import { QuickCheckoutFlowTicketBooked } from './QuickCheckoutFlowTicketBooked';
import { QuickCheckoutFlowTicketNotFound } from './QuickCheckoutFlowTicketNotFound';
import { QuickCheckoutFlowTicketPaymentNotAllowed } from './QuickCheckoutFlowTicketPaymentNotAllowed';
import { QuickCheckoutFlowTicketScanned } from './QuickCheckoutFlowTicketScanned';
import { QuickCheckoutPaymentCallback } from './QuickCheckoutPaymentCallback';
import {
    FlowState,
    getZoneInfoFromFlowState,
    PaymentModalState,
    ZoneInfo,
} from './QuickCheckoutTypes';
import { useParams } from 'react-router';
import { TicketPlusTooManyApprovedTickets } from '../../common/components/ticketplus/TicketPlusTooManyApprovedTickets';
import { Outlet, useOutletContext } from 'react-router-dom';
import { PaymentMethodEnum } from '../../api/Http.ts';
import { PayStationPaymentReceiptView } from '../../common/components/ticketplus/PayStationPaymentReceiptView.tsx';

const QuickCheckoutFlow = ({
    flowState,
    refetchFlowState,
    ticketId,
    paymentModalState,
    onPaymentStateModalConfirm,
}: {
    flowState: FlowState;
    refetchFlowState: () => void;
    ticketId: string;
    paymentModalState?: PaymentModalState;
    onPaymentStateModalConfirm: () => void;
}): JSX.Element => {
    switch (flowState.flowState) {
        case 'PAY_STATION_RECEIPT':
            return (
                <PayStationPaymentReceiptView
                    committedPayStationPaymentIds={
                        flowState.committedPayStationPaymentIds
                    }
                />
            );
        case 'TICKET_SCANNED':
        case 'ALIAS_PENDING':
            return (
                <QuickCheckoutFlowTicketScanned
                    {...flowState}
                    paymentModalState={paymentModalState}
                    onPaymentStateModalConfirm={onPaymentStateModalConfirm}
                />
            );
        case 'TICKET_APPROVED':
            return (
                <QuickCheckoutFlowTicketApproved
                    {...flowState}
                    ticketId={ticketId}
                    refetchFlowState={refetchFlowState}
                    paymentModalState={paymentModalState}
                    onPaymentStateModalConfirm={onPaymentStateModalConfirm}
                />
            );
        case 'TICKET_BOOKED':
            return <QuickCheckoutFlowTicketBooked flowState={flowState} />;
        case 'TICKET_CLAIMED':
            return <TicketPlusClaimed />;
        case 'TOO_MANY_APPROVED_TICKETS':
            return (
                <TicketPlusTooManyApprovedTickets
                    activePaymentType={PaymentMethodEnum.DIRECT}
                />
            );
        case 'TICKET_PAYMENT_NOT_ALLOWED':
            return <QuickCheckoutFlowTicketPaymentNotAllowed />;
        case 'TICKET_NOT_FOUND':
            return <QuickCheckoutFlowTicketNotFound />;
    }
};

export const QuickCheckoutTicket = () => {
    const { ticketId, paymentAttemptId } = useParams();
    const [flowState, writeFlowState] = useServerWrite<
        { ticket: string; hasPaymentAttempt: boolean },
        FlowState,
        unknown
    >(() => ({
        url: `/ui-api/quickcheckout/ticket/claim`,
    }));
    const refetchFlowState = () =>
        writeFlowState({
            ticket: ticketId ?? '',
            hasPaymentAttempt: !!paymentAttemptId,
        });

    useEffect(() => {
        ticketId &&
            writeFlowState({
                ticket: ticketId,
                hasPaymentAttempt: !!paymentAttemptId,
            });
    }, []);

    const [paymentModalState, setPaymentModalState] =
        useState<PaymentModalState>();

    const zoneInfo = getZoneInfoFromFlowState(flowState);

    if (
        ticketId !== undefined &&
        paymentAttemptId !== undefined &&
        paymentAttemptId.length > 0
    ) {
        return (
            <QuickCheckoutPaymentCallback
                paymentAttemptId={paymentAttemptId}
                ticketId={ticketId}
                onPaymentSuccessful={() => {
                    setPaymentModalState(PaymentModalState.SUCCESSFUL);
                    refetchFlowState();
                }}
                onPaymentAborted={() => {
                    setPaymentModalState(PaymentModalState.ABORTED);
                }}
                onPaymentRejected={() => {
                    setPaymentModalState(PaymentModalState.FAILED);
                }}
            />
        );
    }

    return flowState.status === 'success' && ticketId !== undefined ? (
        <>
            <QuickCheckoutFlow
                flowState={flowState.data}
                refetchFlowState={refetchFlowState}
                ticketId={ticketId}
                paymentModalState={paymentModalState}
                onPaymentStateModalConfirm={() =>
                    setPaymentModalState(undefined)
                }
            />
            <ZoneInfoOutlet context={{ zoneInfo }} />
        </>
    ) : null;
};

export function useZoneInfoOutletContext() {
    return useOutletContext<ZoneInfoOutletContext>();
}

function ZoneInfoOutlet({ context }: { context: ZoneInfoOutletContext }) {
    return <Outlet context={context} />;
}

type ZoneInfoOutletContext = {
    zoneInfo?: ZoneInfo;
};
