import { css } from '@emotion/css';
import { Localized } from '../Localized';
import {
    TicketContent,
    TicketContentText,
    TicketTitle,
} from './TicketPlusLayout';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export function PayStationPaymentReceiptView({
    committedPayStationPaymentIds,
}: {
    committedPayStationPaymentIds: string[];
}) {
    const hasMultipleReceipts = committedPayStationPaymentIds.length > 1;
    return (
        <TicketContent>
            <TicketTitle>
                {hasMultipleReceipts ? (
                    <Localized
                        de="Quittungen"
                        fr="Reçus"
                        it="Storico dei pagamenti"
                        en="Receipts"
                    />
                ) : (
                    <Localized
                        de="Quittung"
                        fr="Reçu"
                        it="Ricevuta"
                        en="Receipt"
                    />
                )}
            </TicketTitle>

            <TicketContentText>
                <Localized
                    de={`Hier können Sie die ${hasMultipleReceipts ? 'Quittungen' : 'Quittung'} zum Parkvorgang, den Sie an der Kasse bezahlt haben, herunterladen.`}
                    fr={`Ici, vous pouvez télécharger  de la transaction de stationnement que vous avez payée à la caisse.`}
                    it={`Qui potrai scaricare ${hasMultipleReceipts ? 'le ricevute' : 'la ricevuta'} della transazione di parcheggio che hai pagato alla cassa.`}
                    en={`Here you can download the ${hasMultipleReceipts ? 'receipts' : 'receipt'} for the parking transaction that you paid for at the pay station.`}
                />
            </TicketContentText>
            {committedPayStationPaymentIds.map((payStationPaymentId, index) => (
                <div
                    key={payStationPaymentId}
                    className={css({
                        display: 'flex',
                        width: '100%',
                        marginBottom: '10px',
                    })}
                >
                    <Clickable
                        element="div"
                        className={css({
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '48px',
                            ...Typo.robotoBold,
                            fontSize: '16px',
                            marginTop: '10px',
                            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.30)',
                            background: Colors.darkblue,
                            textTransform: 'uppercase',
                            letterSpacing: '0.8px',
                            color: Colors.white,
                        })}
                        onClick={() =>
                            window.open(
                                `/ui-api/pay-station/payment/${payStationPaymentId}/pdf`,
                                '_blank',
                                'noreferrer',
                            )
                        }
                    >
                        <Localized
                            de={`Quittung ${hasMultipleReceipts ? `${index + 1} ` : ''}herunterladen`}
                            fr={`Télécharger le reçu${hasMultipleReceipts ? ` ${index + 1}` : ''}`}
                            it={`Scarica la ricevuta${hasMultipleReceipts ? ` ${index + 1}` : ''}`}
                            en={`Download receipt${hasMultipleReceipts ? ` ${index + 1}` : ''}`}
                        />
                    </Clickable>
                </div>
            ))}
        </TicketContent>
    );
}
