export function QRCode() {
    return (
        <svg
            width="100%"
            height="32px"
            viewBox="0 0 471 471"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                strokeLinejoin: 'round',
                strokeMiterlimit: 2,
                marginTop: '8px',
            }}
        >
            <g transform="matrix(1,0,0,1,-297.279,-768.92)">
                <g transform="matrix(1,0,0,1,69.1755,45.2606)">
                    <g transform="matrix(1,0,0,1,-202.127,-43.1672)">
                        <path
                            d="M607.396,766.827L430.231,766.827L430.231,943.992L607.396,943.992L607.396,766.827ZM548.052,826.171L489.575,826.171L489.575,884.648L548.052,884.648L548.052,826.171Z"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,-202.127,250.197)">
                        <path
                            d="M607.396,766.827L430.231,766.827L430.231,943.992L607.396,943.992L607.396,766.827ZM548.052,826.171L489.575,826.171L489.575,884.648L548.052,884.648L548.052,826.171Z"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,91.3629,-43.1672)">
                        <path
                            d="M607.396,766.827L430.231,766.827L430.231,943.992L607.396,943.992L607.396,766.827ZM548.052,826.171L489.575,826.171L489.575,884.648L548.052,884.648L548.052,826.171Z"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,0,-1)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,293.612,-1)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,410.303,-1)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,352.035,57.2679)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1,352.035,203.614)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <g transform="matrix(1,0,0,1.49714,410.817,-347.073)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <path
                        d="M493.258,1134.82L580.581,1134.82L580.581,1046.38L521.627,1046.38L521.627,1075.74L493.258,1075.74L493.258,1075.69L434.303,1075.69L434.303,1193.58L493.258,1193.58L493.258,1134.82Z"
                        fill="rgb(17,99,148)"
                    />
                    <g transform="matrix(1,0,0,1.99731,205.462,-1075.91)">
                        <rect
                            x="228.593"
                            y="930.674"
                            width="58.954"
                            height="59.075"
                            fill="rgb(17,99,148)"
                        />
                    </g>
                    <path
                        d="M473.228,929.796L473.228,929.674L375.09,929.674L375.09,988.749L434.055,988.749L434.055,1047.79L493.009,1047.79L493.009,929.796L473.228,929.796Z"
                        fill="rgb(17,99,148)"
                    />
                </g>
            </g>
        </svg>
    );
}
